export default (store) => (next) => (action) => {
  if (action.type !== 'LOGOUT_SUCCESS') return next(action);
  const deleteDeviceToken = store.getState().getIn(['membershipReducer', 'deleteDeviceToken']);
  const { authHeaders } = action.payload;
  if (authHeaders && deleteDeviceToken) {
    action.payload.deleteToken = true;
    deleteDeviceToken(authHeaders);
  }
  return next(action);
};
