import { CALL_API } from 'middlewares/api';

export const SINGLE_EXERCISE_REQUEST = 'SINGLE_EXERCISE_REQUEST';
export const SINGLE_EXERCISE_SUCCESS = 'SINGLE_EXERCISE_SUCCESS';
export const SINGLE_EXERCISE_FAILURE = 'SINGLE_EXERCISE_FAILURE';
export const EXERCISES_REQUEST = 'EXERCISES_REQUEST';
export const EXERCISES_SUCCESS = 'EXERCISES_SUCCESS';
export const EXERCISES_FAILURE = 'EXERCISES_FAILURE';
export const EXERCISE_ANSWER_REQUEST = 'EXERCISE_ANSWER_REQUEST';
export const EXERCISE_ANSWER_SUCCESS = 'EXERCISE_ANSWER_SUCCESS';
export const EXERCISE_ANSWER_FAILURE = 'EXERCISE_ANSWER_FAILURE';
export const HIDE_MODAL = '  HIDE_MODAL';

export const fetchExercises = () => ({
  [CALL_API]: {
    types: [EXERCISES_REQUEST, EXERCISES_SUCCESS, EXERCISES_FAILURE],
    endpoint: `/users/exercises`,
    authenticated: true,
    customConfig: {
      method: 'GET',
    },
  },
});

export const fetchExerciseRequest = exerciseId => ({
  [CALL_API]: {
    types: [
      SINGLE_EXERCISE_REQUEST,
      SINGLE_EXERCISE_SUCCESS,
      SINGLE_EXERCISE_FAILURE,
    ],
    endpoint: `/users/exercises/${exerciseId}`,
    authenticated: true,
    customConfig: {
      method: 'GET',
    },
  },
});

export const submitExerciseRequest = exerciseParams => ({
  [CALL_API]: {
    types: [
      EXERCISE_ANSWER_REQUEST,
      EXERCISE_ANSWER_SUCCESS,
      EXERCISE_ANSWER_FAILURE,
    ],
    endpoint: `/users/answers`,
    authenticated: true,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ answer: exerciseParams }),
    },
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
  payload: {
    isVisible: false,
  },
});
