import { CALL_API } from 'middlewares/api';

export const PROGRAM_REQUEST = 'PROGRAM_REQUEST';
export const PROGRAM_SUCCESS = 'PROGRAM_SUCCESS';
export const PROGRAM_FAILURE = 'PROGRAM_FAILURE';

export const fetchProgram = () => ({
  [CALL_API]: {
    types: [PROGRAM_REQUEST, PROGRAM_SUCCESS, PROGRAM_FAILURE],
    endpoint: `/programs`,
    authenticated: false,
    customConfig: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
});
