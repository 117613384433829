import { CALL_API } from 'middlewares/api';

export const SINGLE_HABIT_REQUEST = 'SINGLE_HABIT_REQUEST';
export const SINGLE_HABIT_SUCCESS = 'SINGLE_HABIT_SUCCESS';
export const SINGLE_HABIT_FAILURE = 'SINGLE_HABIT_FAILURE';
export const HABITS_REQUEST = 'HABITS_REQUEST';
export const HABITS_SUCCESS = 'HABITS_SUCCESS';
export const HABITS_FAILURE = 'HABITS_FAILURE';
export const HABIT_ANSWER_REQUEST = 'HABIT_ANSWER_REQUEST';
export const HABIT_ANSWER_SUCCESS = 'HABIT_ANSWER_SUCCESS';
export const HABIT_ANSWER_FAILURE = 'HABIT_ANSWER_FAILURE';
export const HIDE_MODAL = '  HIDE_MODAL';

export const fetchHabits = () => ({
  [CALL_API]: {
    types: [HABITS_REQUEST, HABITS_SUCCESS, HABITS_FAILURE],
    endpoint: `/users/habits`,
    authenticated: true,
    customConfig: {
      method: 'GET',
    },
  },
});

export const fetchHabitRequest = habitId => ({
  [CALL_API]: {
    types: [SINGLE_HABIT_REQUEST, SINGLE_HABIT_SUCCESS, SINGLE_HABIT_FAILURE],
    endpoint: `/users/habits/${habitId}`,
    authenticated: true,
    customConfig: {
      method: 'GET',
    },
  },
});

export const submitHabitRequest = habitParams => ({
  [CALL_API]: {
    types: [HABIT_ANSWER_REQUEST, HABIT_ANSWER_SUCCESS, HABIT_ANSWER_FAILURE],
    endpoint: `/users/answers`,
    authenticated: true,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ answer: habitParams }),
    },
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
  payload: {
    isVisible: false,
  },
});
