import { CALL_API } from 'middlewares/api';

export const BLOGS_REQUEST = 'BLOGS_REQUEST';
export const BLOGS_SUCCESS = 'BLOGS_SUCCESS';
export const BLOGS_FAILURE = 'BLOGS_FAILURE';
export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';
export const SINGLE_BLOG_REQUEST = 'SINGLE_BLOG_REQUEST';
export const SINGLE_BLOG_SUCCESS = 'SINGLE_BLOG_SUCCESS';
export const SINGLE_BLOG_FAILURE = 'SINGLE_BLOG_FAILURE';
export const BLOG_COMMENT_REQUEST = 'BLOG_COMMENT_REQUEST';
export const BLOG_COMMENT_SUCCESS = 'BLOG_COMMENT_SUCCESS';
export const BLOG_COMMENT_FAILURE = 'BLOG_COMMENT_FAILURE';
export const HIDE_MODAL = '  HIDE_MODAL';

export const fetchBlogs = ( currPage, queryVal, category_id  ) => ({
  [CALL_API]: {
    types: [BLOGS_REQUEST, BLOGS_SUCCESS, BLOGS_FAILURE],
    endpoint: `/articles`,
    customConfig: {
      query: {
        page: currPage || '1',
        per_page: 9,
        query: queryVal || '',
        category_ids: category_id || '',
      }
    },
  },
});

export const fetchCategories = () => ({
  [CALL_API]: {
    types: [CATEGORIES_REQUEST, CATEGORIES_SUCCESS, CATEGORIES_FAILURE],
    endpoint: `/categories`,
    customConfig: {
      method: 'GET',
    },
  },
});

export const fetchBlogRequest = blogId => ({
  [CALL_API]: {
    types: [
      SINGLE_BLOG_REQUEST,
      SINGLE_BLOG_SUCCESS,
      SINGLE_BLOG_FAILURE,
    ],
    endpoint: `/articles/${blogId}`,
    customConfig: {
      method: 'GET',
    },
  },
});


export const submitCommentRequest = commentParams => ({
  [CALL_API]: {
    types: [
      BLOG_COMMENT_REQUEST,
      BLOG_COMMENT_SUCCESS,
      BLOG_COMMENT_FAILURE,
    ],
    endpoint: `/users/comments`,
    authenticated: true,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: commentParams.comment,
         rating: commentParams.rating,
         article_id: commentParams.article_id}),
    },
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
  payload: {
    isVisible: false,
  },
});
