import React from 'react';
import { fb } from './fb.js';

class CustomerChat extends React.PureComponent {
  componentDidMount() {
    this.timeout = setTimeout(() => {
      fb(FB => this.timeout && FB.XFBML.parse());
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    delete this.timeout;
  }

  render() {
    return (
      <>
        {/*
        <div
          className="fb-customerchat"
          attribution="setup_tool"
          page_id="402746250486818"
          logged_in_greeting="Hi! We're here to answer any questions you may have."
          logged_out_greeting="Hi! We're here to answer any questions you may have."
          minimized="false"
        />
        */}
      </>
    );
  }
}

export default CustomerChat;
