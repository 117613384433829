import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { configHeader } from 'components/DashboardHeader/redux/actions';
import moment from 'moment';

const renderRoute = ({ props, container: Component, configs, dashboardTitle }) => {
  const { authenticated, dashboard } = configs;
  const { location } = props;
  const user = JSON.parse(localStorage.getItem('currentUser'));

  if (authenticated) {
    if (localStorage.getItem('authHeaders')) {
      dashboardTitle(dashboard);
      if (!user.email_confirmed && location.pathname !== '/confirm-email') {
        return (
          <Redirect
            {...configs}
            {...props}
            to={{
              pathname: '/confirm-email',
              state: { from: location },
            }}
          />
        );
      }
      if (
        !user.payment_completed &&
        location.pathname !== '/complete-payment'
      ) {
        return (
          <Redirect
            {...configs}
            {...props}
            to={{
              pathname: '/complete-payment',
              state: { from: location },
            }}
          />
        );
      }

      if (
        user.payment_completed &&
        !['confirm-payment', '/complete-payment'].includes(location.pathname) &&
        !user.initial_assesment_completed &&
        !location.pathname.includes('/assesment')
      ) {
        return (
          <Redirect
            {...configs}
            {...props}
            to={{
              pathname: `/assesment/${(user.initial_assesment && user.initial_assesment.step) || 1}`,
              state: { from: location },
            }}
          />
        );
      }
      if (
        user.initial_assesment_completed &&
        user.payment_completed &&
        moment().diff(moment(user.program.start_date), 'hours') < 0 &&
        !location.pathname.includes('/not-started')
      ) {
        return (
          <Redirect
            to={{
              pathname: `/not-started`,
              state: { from: location },
            }}
          />
        );
      }

      return <Component {...configs} {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: location },
        }}
      />
    );
  }

  return <Component {...configs} {...props} />;
};

renderRoute.propTypes = {
  props: PropTypes.object,
  container: PropTypes.func,
  configs: PropTypes.object,
  dashboardTitle: PropTypes.func,
  location: PropTypes.object,
};

const ExtendedRoute = ({ dashboardTitle, container, ...configs }) => (
  <Route {...configs} render={(props) => renderRoute({ props, container, configs, dashboardTitle })} />
);

ExtendedRoute.propTypes = {
  dashboardTitle: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    dashboardTitle: (dashboard) => dispatch(configHeader(dashboard)),
  };
}

export default connect(null, mapDispatchToProps)(ExtendedRoute);
