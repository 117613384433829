import { Map } from 'immutable';
import {
  BLOGS_REQUEST,
  BLOGS_SUCCESS,
  BLOGS_FAILURE,
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAILURE,
  SINGLE_BLOG_REQUEST,
  SINGLE_BLOG_SUCCESS,
  SINGLE_BLOG_FAILURE,
  BLOG_COMMENT_REQUEST,
  BLOG_COMMENT_SUCCESS,
  BLOG_COMMENT_FAILURE,
  HIDE_MODAL,
} from './actions';

const INITIAL_STATE = Map({
  isFetching: false,
  blogs: [],
  blog: {},
  modal: { isVisible: false },
  totalPages: 1,
  currPage: 1,
  categories: [],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BLOGS_REQUEST:
      return state.set('isFetching', true);
    case BLOGS_SUCCESS: {
      const {
        response: { articles, meta },
      } = action;

      return state
        .set('isFetching', false)
        .set('blogs', articles)
        .set('totalPages', meta.total_pages);
    }
    case BLOGS_FAILURE:
      return state.set('isFetching', false);

    case CATEGORIES_REQUEST:
      return state.set('isFetching', true);
    case CATEGORIES_SUCCESS: {
      const {
        response: { categories },
      } = action;

      return state
        .set('isFetching', false)
        .set('categories', categories);
    }
    case CATEGORIES_FAILURE:
      return state.set('isFetching', false);

    case SINGLE_BLOG_REQUEST:
      return state.set('isFetching', true);
    case SINGLE_BLOG_SUCCESS:
      return state
        .set('isFetching', false)
        .set('blog', action.response.article);
    case SINGLE_BLOG_FAILURE:
      return state.set('isFetching', false);

    case BLOG_COMMENT_REQUEST:
      return state.set('isFetching', true).set('modal', { isVisible: false });
    case BLOG_COMMENT_SUCCESS:
      const { response: { comment }, } = action;
      let blog = state.get('blog');
      blog.comments.push(comment);
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Comment Submitted',
      }).set({'blog': blog});
    case BLOG_COMMENT_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
      });

    case HIDE_MODAL:
      return state.set('modal', { isVisible: false });
      default:
      return state;
  }
};
