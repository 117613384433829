import { Map } from 'immutable';
import { DASHBOARD_HEADER_TITLE } from './actions';

const INITIAL_STATE = Map({});

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_HEADER_TITLE:
      return state
        .set('title', action.title)
        .set('visible', action.visible)
        .set('progress', action.progress)
        .set('button', action.button);
    default:
      return state;
  }
};
