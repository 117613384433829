import { Map } from 'immutable';
import { dateReduce } from 'utils/reducerHelpers';
import {
  NUTIBITES_REQUEST,
  NUTIBITES_SUCCESS,
  NUTIBITES_FAILURE,
  SINGLE_NUTIBITE_REQUEST,
  SINGLE_NUTIBITE_SUCCESS,
  SINGLE_NUTIBITE_FAILURE,
  NUTIBITE_ANSWER_REQUEST,
  NUTIBITE_ANSWER_SUCCESS,
  NUTIBITE_ANSWER_FAILURE,
  HIDE_MODAL,
} from './actions';

const INITIAL_STATE = Map({
  isFetching: false,
  nutibite: {},
  modal: { isVisible: false },
});

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NUTIBITES_REQUEST:
      return state.set('isFetching', true);
    case NUTIBITES_SUCCESS: {
      const {
        response: { lessons },
      } = action;

      return state
        .set('isFetching', false)
        .set('nutibites', dateReduce(lessons));
    }
    case NUTIBITES_FAILURE:
      return state.set('isFetching', false);
    case SINGLE_NUTIBITE_REQUEST:
      return state.set('isFetching', true);
    case SINGLE_NUTIBITE_SUCCESS:
      return state
        .set('isFetching', false)
        .set('nutibite', action.response.lesson);
    case SINGLE_NUTIBITE_FAILURE:
      return state.set('isFetching', false);
    case NUTIBITE_ANSWER_REQUEST:
      return state.set('isFetching', true).set('modal', { isVisible: false });
    case NUTIBITE_ANSWER_SUCCESS:
      return state
        .set('isFetching', false)
        .set('answer', action.response.answer)
        .set('modal', {
          isVisible: true,
          title: 'Answer Submitted',
        });
    case NUTIBITE_ANSWER_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
      });
    case HIDE_MODAL:
      return state.set('modal', { isVisible: false });
    default:
      return state;
  }
};
