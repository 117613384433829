import React from 'react';
import { Route, Switch } from 'react-router';
import posed, { PoseGroup } from 'react-pose';
import NotFound from 'components/NotFound';
import { routes } from './constants';
import ExtendedRoute from './components/ExtendedRoute';
import Loader from './components/Loader';

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
});

const Router = () => (
  <Route
    render={({ location }) => (
      <Loader location={location}>
        <PoseGroup>
          <RouteContainer key={location.pathname} style={{ height: '100%' }}>
            <Switch location={location}>
              {routes.map(route => (
                <ExtendedRoute {...route} key={route.path} />
              ))}
              <Route component={NotFound} />
            </Switch>
          </RouteContainer>
        </PoseGroup>
      </Loader>
    )}
  />
);

export default Router;
