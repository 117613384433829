import progress from 'assets/my-progress.svg';
import nutibites from 'assets/lessons.svg';
import habits from 'assets/habits.svg';
import exercise from 'assets/exercise.svg';
import assessment from 'assets/assessment.svg';
import msg from 'assets/msg-coach.svg';

export const links = [
  {
    name: 'Progress',
    route: '/progress',
    image: progress,
  },
  {
    name: 'Chat',
    route: '/chat',
    notificationKey: 'pending_messages_count',
    image: msg,
  },
  {
    name: 'Nutibites',
    route: '/nutibites',
    notificationKey: 'pending_lessons_count',
    image: nutibites,
  },
  {
    name: 'Habits',
    route: '/habits',
    notificationKey: 'pending_habits_count',
    image: habits,
  },
  {
    name: 'Exercises',
    route: '/exercises',
    notificationKey: 'pending_exercises_count',
    image: exercise,
  },
  {
    name: 'Check ups',
    route: '/check-ups',
    notificationKey: 'pending_checkups_count',
    image: assessment,
  },
];
