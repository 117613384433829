import pick from 'lodash/pick';

export const createQueryString = queryObj => {
  let queryString = Object.keys(queryObj).reduce(
    (query, currentKey) => `${query + currentKey}=${queryObj[currentKey]}&`,
    '?',
  );
  queryString = queryString.slice(0, -1);
  return queryString;
};

export const extractAuthHeaders = headers => {
  const authHeaders = {};
  headers.forEach((value, key) => {
    authHeaders[key] = value;
  });
  return pick(authHeaders, ['client', 'expiry', 'token', 'uid']);
};

export const request = (url, options, isDownloading = false) => {
  const requestChain = fetch(url, options).then(checkStatus);
  if (isDownloading) {
    return requestChain.then(parseBLOB);
  }

  return requestChain.then(parseJSON);
};

const parseJSON = async response => {
  if (response.status === 204 || response.status === 205) {
    return {};
  }

  const responseText = await response.text();
  if (responseText.trim()) {
    return {
      response: JSON.parse(responseText),
      responseHeaders: extractAuthHeaders(response.headers),
    };
  }

  return {};
};

const parseBLOB = async response => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const file = await response.blob();
  return file;
};

const checkStatus = async response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  const responseObj = await parseJSON(response);
  error.response = responseObj.response;
  error.status = response.status;
  throw error;
};
