import styled from 'styled-components';
import Colors from 'theme/Colors';
import Breakpoints from 'theme/Breakpoints';

export const AppWrapper = styled.div`
  * {
      font-family: 'Nunito', sans-serif;
      &::selection {
        background: ${Colors.aquamarine};
        color: ${Colors.navy};
      }
      &::-moz-selection {
        background: ${Colors.aquamarine};
        color: ${Colors.navy};
      }
    }
  }

  ${props =>
    props.private
      ? `
      height: 100%;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: 250px 1fr;
      grid-template-rows: auto 1fr;
      grid-template-areas:
      "sidebar header"
      "sidebar main"
      "sidebar main";

      > :nth-child(2) {
        grid-area: sidebar;
      }

      > :nth-child(4) {
        grid-area: header;
      }

      > :nth-child(1) {
        grid-area: main;
        box-sizing: border-box;
        padding-right: 20px;
        max-width: 100%;
      }

      @media screen and (max-width: ${Breakpoints.sm}) {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-gap: 20px;

        grid-template-areas:
        "hamburger header"
        "main main"
        "main main";

        > :nth-child(3) {
          grid-area: hamburger;
          padding-left: 20px;
          padding-top: 20px;
        }

        > :nth-child(4) {
          grid-area: header;
          margin-top: 10px;
        }

        > :nth-child(1) {
          grid-area: main;
          box-sizing: border-box;
          padding-right: 20px;
          padding-left: 20px;
        }
      }`
      : ''};
`;

export const RouterWrapper = styled.div`
  ${props =>
    props.padd
      ? `
  padding-left: 0;
  padding-right: 0;

  @media screen and (max-width: ${Breakpoints.lg}) {
    padding-left: 0;
    padding-right: 0;
  }`
      : `
      `}
`;

export const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageLoaderChildrenWrapper = styled.div`
  animation-name: modalFade;
  animation-duration: 3s;
  min-height: calc(100vh - 8em);
  @keyframes modalFade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
