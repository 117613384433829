import { Map } from 'immutable';
import {
  TESTIMONIALS_REQUEST,
  TESTIMONIALS_SUCCESS,
  TESTIMONIALS_FAILURE,
} from './actions';

const INITIAL_STATE = Map({
  isFetching: false,
  testimonials: [],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TESTIMONIALS_REQUEST:
      return state.set('isFetching', true);
    case TESTIMONIALS_SUCCESS: {
      const {
        response: { testimonials },
      } = action;

      return state
        .set('isFetching', false)
        .set('testimonials', testimonials);
    }
    case TESTIMONIALS_FAILURE:
      return state.set('isFetching', false);

    default:
      return state;
  }
};
