import { API_BASE_URL } from 'utils/constants';
import { createQueryString, request } from 'utils/requestHelpers';
import { loadFromLocalStorage } from 'utils/localStorageHelpers';
import { logout } from 'components/Membership/redux/actions';

function callApi(endpoint, authenticated, isDownloading = false, customConfig = {}) {
  let queryableEndpoint = endpoint;
  const { query, ...modifiedCustomConfig } = customConfig;
  // append query string to the endpoint if existed
  if (query !== undefined) {
    queryableEndpoint += createQueryString(query);
  }

  const config = {
    headers: {},
    ...modifiedCustomConfig,
  };

  let authHeaders = null;
  if (authenticated) {
    authHeaders = loadFromLocalStorage('authHeaders');
    config.headers = {
      ...config.headers,
      ...authHeaders,
    };
  }

  return request(API_BASE_URL + queryableEndpoint, config, isDownloading);
}

export const CALL_API = Symbol('Call API');

/* eslint-disable no-unused-vars */
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const {
    endpoint,
    types,
    authenticated = false,
    isDownloading = false,
    customConfig,
    params,
    callbacks = {},
    callbackArguments = {},
  } = callAPI;

  if (authenticated) {
    const authHeaders = loadFromLocalStorage('authHeaders');
    if (!authHeaders) return next(logout());
  }

  const [requestType, successType, errorType] = types;

  next({ type: requestType, ...params });

  const { requestCallback, successCallback, failureCallback } = callbacks;

  if (requestCallback) requestCallback();

  return callApi(endpoint, authenticated, isDownloading, customConfig).then(
    ({ response, responseHeaders }) => {
      try {
        return next({
          response,
          responseHeaders,
          authenticated,
          isDownloading,
          type: successType,
          ...params,
        });
      } finally {
        if (successCallback) successCallback({ response, responseHeaders, callbackArguments });
      }
    },
    (error) => {
      try {
        return error.status === 401 && authenticated
          ? next(logout())
          : next({ error: error.response, type: errorType, ...params });
      } finally {
        if (failureCallback) failureCallback(error);
      }
    },
  );
};
