import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'theme/Colors';
import { NanoTitle } from 'components/Global/Styled';
import { RadialProgressWrapper, TextWrapper } from './components/Styled';

const RadialProgress = ({ percentage, title, color }) => {
  const pointX =
    18 + 15.9155 * Math.cos((percentage / 100) * 2 * Math.PI - 0.5 * Math.PI);
  const pointY =
    18 + 15.9155 * Math.sin((percentage / 100) * 2 * Math.PI - 0.5 * Math.PI);

  return (
    <RadialProgressWrapper>
      <svg viewBox="0 0 36 36" width="100%" height="100%">
        <path
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke={Colors.spruce}
          strokeWidth="0.2px"
        />
        <path
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke={color}
          strokeWidth="1px"
          strokeDasharray={`${percentage}, 100`}
          style={{ strokeLinecap: 'round' }}
        />

        <circle cx={pointX} cy={pointY} r="1" fill={Colors.spruce} />
        <circle
          cx={pointX}
          cy={pointY}
          r="1.5"
          stroke={Colors.spruce}
          strokeWidth="0.2px"
          fill="none"
        />
      </svg>
      <TextWrapper>
        <NanoTitle>{title}</NanoTitle>
        <NanoTitle>
          <b>{percentage} %</b>
        </NanoTitle>
      </TextWrapper>
    </RadialProgressWrapper>
  );
};

RadialProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default RadialProgress;
