const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV || 'development') {
    case 'development':
      return 'http://localhost:3000/v1';
    case 'stg-dev':
      return 'https://dev-nutivate-api.thewebops.com/v1';
    case 'staging':
      return 'https://nutivate-api.thewebops.com/v1';
    case 'production':
      return 'https://api.nutivate.com/v1';
    default:
      return 'https://dev-nutivate-api.thewebops.com/v1';
  }
};

const getStripePK = () => {
  switch (process.env.REACT_APP_ENV || 'development') {
    case 'development':
      return "";
      case 'stg-dev':
      return "pk_test_51OTKCCBCQEyJcln9VyRY0U1oIDcQhPTtd0CcVqwNG2NKTAYaAHKjQRJtMaTyDwETIP1U1twIqnZRn2Mnh4NBz6hU005wrjt9aW";
    case 'staging':
      return "pk_test_51OTKCCBCQEyJcln9VyRY0U1oIDcQhPTtd0CcVqwNG2NKTAYaAHKjQRJtMaTyDwETIP1U1twIqnZRn2Mnh4NBz6hU005wrjt9aW";
    case 'production':
      return "pk_live_51OKeCjCdga1KMsNbxgId1TsZiOcxblTxNhvXRI5UBXSZn8XjqWhppS287tsFUWwOV1nHoAVPhj88YhhQC1R0lRiA00IIeiqZfH";
    default:
      return "pk_test_51OTKCCBCQEyJcln9VyRY0U1oIDcQhPTtd0CcVqwNG2NKTAYaAHKjQRJtMaTyDwETIP1U1twIqnZRn2Mnh4NBz6hU005wrjt9aW";
  }
};

const getCableUrl = () => `${getBaseUrl().slice(0, -3)}/chat_engine/cable`;

export const API_BASE_URL = getBaseUrl();
export const CABLE_BASE_URL = getCableUrl();
export const STRIPE_PUBLICK_KEY = getStripePK();
