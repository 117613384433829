import { CALL_API } from 'middlewares/api';

export const INQUIRY_REQUEST = 'INQUIRY_REQUEST';
export const INQUIRY_SUCCESS = 'INQUIRY_SUCCESS';
export const INQUIRY_FAILURE = 'INQUIRY_FAILURE';

export const HIDE_MODAL = 'HIDE_MODAL';

export const postInquiryRequest = inquiryAttributes => ({
  [CALL_API]: {
    types: [INQUIRY_REQUEST, INQUIRY_SUCCESS, INQUIRY_FAILURE],
    endpoint: `/inquiries`,
    customConfig: {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ inquiry: inquiryAttributes }),
    },
  },
});

export const hideModalAction = () => ({
  type: HIDE_MODAL,
  payload: {
    isVisible: false,
  },
});
