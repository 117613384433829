import { Map } from 'immutable';
import {
  INQUIRY_REQUEST,
  INQUIRY_SUCCESS,
  INQUIRY_FAILURE,
  HIDE_MODAL,
} from './actions';

const INITIAL_STATE = Map({ isFetching: false });

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INQUIRY_REQUEST:
      return state.set('isFetching', true);
    case INQUIRY_SUCCESS:
      return state
        .set('isFetching', false)
        .set('inquiry', action.response.inquiry)
        .set('modal', {
          isVisible: true,
          title: 'Inquiry Received',
          description: "We received your inquiry, we'll get back to you soon!",
        });
    case INQUIRY_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
        description: 'Name, email, message needs to be filled!',
      });
    case HIDE_MODAL:
      return state.set('modal', {
        isVisible: false,
      });
    default:
      return state;
  }
};
