import { CALL_API } from 'middlewares/api';

export const PROGRESS_REQUEST = 'PROGRESS_REQUEST';
export const PROGRESS_SUCCESS = 'PROGRESS_SUCCESS';
export const PROGRESS_FAILURE = 'PROGRESS_FAILURE';

export const fetchProgress = () => ({
  [CALL_API]: {
    types: [PROGRESS_REQUEST, PROGRESS_SUCCESS, PROGRESS_FAILURE],
    endpoint: `/users/progress`,
    authenticated: true,
    customConfig: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
});
