import React from 'react';
import { Map } from 'immutable';
import { loadFromLocalStorage } from 'utils/localStorageHelpers';
import { ASSESMENT_STEP_SUCCESS } from 'components/Assesment/redux/actions';
import { NUTIBITE_ANSWER_SUCCESS } from 'components/Nutibites/redux/actions';
import { HABIT_ANSWER_SUCCESS } from 'components/Habits/redux/actions';
import { EXERCISE_ANSWER_SUCCESS } from 'components/Exercises/redux/actions';
import { CHECKUP_SUBMIT_SUCCESS } from 'components/CheckUp/redux/actions';
//import { FETCH_MESSAGES_SUCCESS } from 'components/Chat/redux/actions';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  COMPLETE_PAYMENT_REQUEST,
  COMPLETE_PAYMENT_SUCCESS,
  COMPLETE_PAYMENT_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_FORM_REQUEST,
  RESET_PASSWORD_FORM_SUCCESS,
  RESET_PASSWORD_FORM_FAILURE,
  COMPLETE_PROFILE_REQUEST,
  COMPLETE_PROFILE_SUCCESS,
  COMPLETE_PROFILE_FAILURE,
  CONFIRM_PROFILE_REQUEST,
  CONFIRM_PROFILE_SUCCESS,
  CONFIRM_PROFILE_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  HIDE_MODAL,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  LOGOUT_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_PAGE_SETTINGS_SUCCESS,
  FETCH_PAGE_SETTINGS_REQUEST,
  FETCH_PAGE_SETTINGS_FAILURE,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAILURE,
  SET_FIRE_BASE_PERMISSIONS,
} from './actions';

const INITIAL_STATE = Map({
  isFetching: false,
  modal: { isVisible: false },
  clientSecret: '',
  currentUser: loadFromLocalStorage('currentUser'),
  pageSettings: {},
});

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  const { response, error } = action;
  let errorDesc = '';

  for (const property in error) {
    errorDesc += `${property}: ${error[property]}\n`;
  }

  switch (action.type) {
    case LOGIN_REQUEST:
      return state.set('isFetching', true).set('modal', { isVisible: false });
    case LOGIN_SUCCESS:
    case FETCH_USER_SUCCESS: {
      localStorage.setItem('currentUser', JSON.stringify(response.user));
      return state.set('isFetching', false).set('currentUser', response.user);
    }
    case LOGIN_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong!',
        description: error && error.errors && error.errors[0],
      });
    case FETCH_USER_REQUEST:
      return state.set('isFetching', true);
    case COMPLETE_PAYMENT_REQUEST:
      return state.set('isFetching', true).set('clientSecret', '');
    case COMPLETE_PAYMENT_SUCCESS:
      return state
        .set('isFetching', false)
        .set('clientSecret', action.response.client_secret)
        .set('paymentAmount', action.response.amount)
        .set('paymentCurrency', action.response.currency);
    case COMPLETE_PAYMENT_FAILURE:
      return state.set('isFetching', false);
    case SIGN_UP_REQUEST:
      return state.set('isFetching', true);
    case SIGN_UP_SUCCESS:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Account Created',
        description: 'Check your email for the confirmation OTP',
      });
    case SIGN_UP_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
        description: error.errors && error.errors.full_messages.map((fullMessage) => <span> {`${fullMessage}`}</span>),
      });
    case RESET_PASSWORD_REQUEST:
      return state.set('isFetching', true);
    case RESET_PASSWORD_SUCCESS:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Email Sent',
        description: 'Check your email for the reset password link',
      });
    case RESET_PASSWORD_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
        description: 'Email not found on our system',
      });
    case RESEND_OTP_REQUEST:
      return state.set('isFetching', true);
    case RESEND_OTP_SUCCESS:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Email Sent',
        description: 'Check your email for the confirmation link',
      });
    case RESEND_OTP_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
        description: 'Email not found on our system',
      });
    case RESET_PASSWORD_FORM_REQUEST:
      return state.set('isFetching', true);
    case RESET_PASSWORD_FORM_SUCCESS:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Password Changed',
        description: 'Click sign in with new credentials',
      });
    case RESET_PASSWORD_FORM_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
        description: 'Password is too short, minimum is 6 characters',
      });
    case COMPLETE_PROFILE_REQUEST:
      return state.set('isFetching', true);
    case COMPLETE_PROFILE_SUCCESS: {
      localStorage.setItem('currentUser', JSON.stringify(response.user));

      return state
        .set('isFetching', false)
        .set('modal', {
          isVisible: true,
          title: 'Profile completed',
          description: 'Click sign in with your email and password',
        })
        .set('currentUser', response.user);
    }
    case COMPLETE_PROFILE_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
        description: `${errorDesc}`,
      });
    case CONFIRM_PROFILE_REQUEST:
      return state.set('isFetching', true);
    case CONFIRM_PROFILE_SUCCESS:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Profile Confirmed',
        description: 'Login with your email and password',
      });
    case CONFIRM_PROFILE_FAILURE:
      return state.set('isFetching', false);
    case CONFIRM_EMAIL_REQUEST:
      return state.set('isFetching', true);
    case CONFIRM_EMAIL_SUCCESS:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Email Confirmed',
        description: 'You successfully confirmed your email',
      });
    case CONFIRM_EMAIL_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
        description: error.message,
      });
    case HIDE_MODAL:
      return state.set('modal', { isVisible: false });
    case EDIT_PROFILE_REQUEST:
      return state.set('isFetching', true);
    case EDIT_PROFILE_SUCCESS:
      return state.set('isFetching', false).set('currentUser', action.response.user).set('modal', {
        isVisible: true,
        title: 'Profile Updated',
        description: 'Keep nutivating',
      });
    case EDIT_PROFILE_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong!',
        description: '',
      });
    case CHANGE_PASSWORD_REQUEST:
      return state.set('isFetching', true);
    case CHANGE_PASSWORD_SUCCESS:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Password Changed',
        description: '',
      });
    case CHANGE_PASSWORD_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong!',
        description: error.errors && error.errors.full_messages,
      });
    case ASSESMENT_STEP_SUCCESS: {
      let currentUser = state.get('currentUser');
      currentUser = {
        ...currentUser,
        initial_assesment_completed: action.response.initial_assesment.user.initial_assesment_completed,
      };

      localStorage.setItem('currentUser', JSON.stringify(currentUser));

      return state.set('currentUser', {
        ...state.get('currentUser'),
        initial_assesment: action.response.initial_assesment,
        initial_assesment_completed: action.response.initial_assesment.user.initial_assesment_completed,
      });
    }
    case NUTIBITE_ANSWER_SUCCESS: {
      let currentUser = state.get('currentUser');

      currentUser = {
        ...currentUser,
        pending_lessons_count: currentUser.pending_lessons_count - 1,
      };

      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      return state.set('currentUser', currentUser);
    }

    case HABIT_ANSWER_SUCCESS: {
      let currentUser = state.get('currentUser');
      currentUser = {
        ...currentUser,
        pending_habits_count: currentUser.pending_habits_count - 1,
      };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      return state.set('currentUser', currentUser);
    }

    case EXERCISE_ANSWER_SUCCESS: {
      let currentUser = state.get('currentUser');
      currentUser = {
        ...currentUser,
        pending_exercises_count: currentUser.pending_exercises_count - 1,
      };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      return state.set('currentUser', currentUser);
    }

    case CHECKUP_SUBMIT_SUCCESS: {
      let currentUser = state.get('currentUser');
      currentUser = {
        ...currentUser,
        pending_checkups_count: response.checkup.submitted
          ? currentUser.pending_checkups_count - 1
          : currentUser.pending_checkups_count,
      };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      return state.set('currentUser', currentUser);
    }

    case LOGOUT_SUCCESS:
      return state.set('isFetching', false);

    case FETCH_PAGE_SETTINGS_REQUEST: {
      return state.set('isFetching', true);
    }

    case FETCH_PAGE_SETTINGS_SUCCESS: {
      return state.set('isFetching', false).set('pageSettings', response.page_settings);
    }

    case FETCH_PAGE_SETTINGS_FAILURE: {
      return state.set('isFetching', false);
    }

    case USER_INFO_REQUEST: {
      return state.set('isFetching', true);
    }

    case USER_INFO_SUCCESS: {
      return state.set('isFetching', false).set('currentUser', response.user);
    }

    case USER_INFO_FAILURE: {
      return state.set('isFetching', false);
    }

    case SET_FIRE_BASE_PERMISSIONS: {
      const { deleteDeviceToken, setFirebaseToken } = action.payload;
      return state.set('setFirebaseToken', setFirebaseToken).set('deleteDeviceToken', deleteDeviceToken);
    }

    default:
      return state;
  }
};
