import { CALL_API } from 'middlewares/api';

export const TESTIMONIALS_REQUEST = 'TESTIMONIALS_REQUEST';
export const TESTIMONIALS_SUCCESS = 'TESTIMONIALS_SUCCESS';
export const TESTIMONIALS_FAILURE = 'TESTIMONIALS_FAILURE';

export const fetchTestimonials = () => ({
  [CALL_API]: {
    types: [TESTIMONIALS_REQUEST, TESTIMONIALS_SUCCESS, TESTIMONIALS_FAILURE],
    endpoint: `/testimonials`,
    customConfig: {
      method: 'GET',
    },
  },
});
