import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Title } from 'components/Global/Styled';
import RadialProgress from 'components/Global/RadialProgress';
import Colors from 'theme/Colors';
import { connect } from 'react-redux';
import { fetchProgress } from 'components/Progress/redux/actions';
import {
  DashboardHeaderWrapper,
  ProgressWrapper,
  DashboardLeftWrapper,
} from './components/Styled';

const DashboardHeader = ({
  visible,
  title,
  progress,
  consistencyPercentage,
  nutibitesPercentage,
  habitsPercentage,
  exercisesPercentage,
  progressRequest,
  button,
}) => {
  if (progress) {
    progressRequest();
  }

  let percentage;
  let color;

  switch (title) {
    case 'EXERCISES':
    case 'EXERCISE':
      percentage = exercisesPercentage;
      color = Colors.blueViolet;
      break;

    case 'HABITS':
    case 'HABIT':
      percentage = habitsPercentage;
      color = Colors.lightRed;
      break;

    case 'NUTIBITESS':
    case 'NUTIBITES':
      percentage = nutibitesPercentage;
      color = Colors.neonBlue;
      break;

    default:
      percentage = consistencyPercentage;
      color = Colors.neonGreen;
  }

  return (
    <Fragment>
      {visible && (
        <DashboardHeaderWrapper>
          <DashboardLeftWrapper button={button}>
            <Title>{title}</Title>
          </DashboardLeftWrapper>
          <ProgressWrapper>
            {progress && (
              <RadialProgress
                percentage={percentage ? +percentage.toFixed(1) : 0}
                color={color}
                title="Consistent"
              />
            )}
          </ProgressWrapper>
        </DashboardHeaderWrapper>
      )}
    </Fragment>
  );
};

DashboardHeader.propTypes = {
  consistencyPercentage: PropTypes.number,
  nutibitesPercentage: PropTypes.number,
  habitsPercentage: PropTypes.number,
  exercisesPercentage: PropTypes.number,
  title: PropTypes.string,
  progress: PropTypes.bool,
  visible: PropTypes.bool,
  progressRequest: PropTypes.func,
  button: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    title: state.getIn(['dashboard', 'title']),
    progress: state.getIn(['dashboard', 'progress']),
    visible: state.getIn(['dashboard', 'visible']),
    button: state.getIn(['dashboard', 'button']),
    consistencyPercentage: state.getIn([
      'progressReducer',
      'consistencyPercentage',
    ]),
    nutibitesPercentage: state.getIn([
      'progressReducer',
      'nutibitesPercentage',
    ]),
    habitsPercentage: state.getIn(['progressReducer', 'habitsPercentage']),
    exercisesPercentage: state.getIn([
      'progressReducer',
      'exercisesPercentage',
    ]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    progressRequest: () => dispatch(fetchProgress()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardHeader);
