import { CALL_API } from 'middlewares/api';
import { loadFromLocalStorage } from 'utils/localStorageHelpers';
import history from 'history.js';

export const ASSESMENT_STEP_REQUEST = 'ASSESMENT_STEP_REQUEST';
export const ASSESMENT_STEP_SUCCESS = 'ASSESMENT_STEP_SUCCESS';
export const ASSESMENT_STEP_FAILURE = 'ASSESMENT_STEP_FAILURE';

export const HIDE_MODAL = 'HIDE_MODAL';

export const submitStep = (assesment, step, answers) => ({
  [CALL_API]: {
    types: [ASSESMENT_STEP_REQUEST, ASSESMENT_STEP_SUCCESS, ASSESMENT_STEP_FAILURE],
    endpoint: `/users/initial_assesments`,
    authenticated: true,
    customConfig: {
      method: !assesment ? 'POST' : 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        initial_assesment: { assesment_step: step, ...answers },
      }),
    },
    callbacks: {
      successCallback: submitSuccess,
    },
  },
});

const submitSuccess = ({ response }) => {
  const user = loadFromLocalStorage('currentUser');
  user.initial_assesment = response.initial_assesment;

  const normalizedUser = JSON.stringify(user);
  localStorage.setItem('currentUser', normalizedUser);
  return response.initial_assesment.user.initial_assesment_completed
    ? history.replace('/profile')
    : history.replace(`/assesment/${response.initial_assesment.step + 1}`);
};

export const hideModal = () => ({
  type: HIDE_MODAL,
  payload: {
    isVisible: false,
  },
});
