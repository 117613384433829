import { Map } from 'immutable';
import { dateReduce } from 'utils/reducerHelpers';
import {
  HABITS_REQUEST,
  HABITS_SUCCESS,
  HABITS_FAILURE,
  SINGLE_HABIT_REQUEST,
  SINGLE_HABIT_SUCCESS,
  SINGLE_HABIT_FAILURE,
  HABIT_ANSWER_REQUEST,
  HABIT_ANSWER_SUCCESS,
  HABIT_ANSWER_FAILURE,
  HIDE_MODAL,
} from './actions';

const INITIAL_STATE = Map({
  isFetching: false,
  habit: {},
  modal: { isVisible: false },
});

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HABITS_REQUEST:
      return state.set('isFetching', true);
    case HABITS_SUCCESS: {
      const {
        response: { habits },
      } = action;

      return state.set('isFetching', false).set('habits', dateReduce(habits));
    }
    case HABITS_FAILURE:
      return state.set('isFetching', false);
    case SINGLE_HABIT_REQUEST:
      return state.set('isFetching', true);
    case SINGLE_HABIT_SUCCESS:
      return state.set('isFetching', false).set('habit', action.response.habit);
    case SINGLE_HABIT_FAILURE:
      return state.set('isFetching', false);
    case HABIT_ANSWER_REQUEST:
      return state.set('isFetching', true).set('modal', { isVisible: false });
    case HABIT_ANSWER_SUCCESS:
      return state
        .set('isFetching', false)
        .set('answer', action.response.answer)
        .set('modal', {
          isVisible: true,
          title: 'Answer Submitted',
        });
    case HABIT_ANSWER_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
      });
    case HIDE_MODAL:
      return state.set('modal', { isVisible: false });
    default:
      return state;
  }
};
