import { combineReducers } from 'redux-immutable';
import membershipReducer from 'components/Membership/redux/reducer';
import programReducer from 'components/Program/redux/reducer';
import nutibitesReducer from 'components/Nutibites/redux/reducer';
import habitsReducer from 'components/Habits/redux/reducer';
import exercisesReducer from 'components/Exercises/redux/reducer';
import blogsReducer from 'components/Blogs/redux/reducer';
import faqsReducer from 'components/FAQs/redux/reducer';
import homeReducer from 'components/HomePage/redux/reducer';
import assesmentReducer from 'components/Assesment/redux/reducer';
import inquiryReducer from 'components/Contact/redux/reducer';
import progressReducer from 'components/Progress/redux/reducer';
import checkups from 'components/CheckUp/redux/reducer';
import dashboard from 'components/DashboardHeader/redux/reducer';
import { connectRouter } from 'connected-react-router';
import { firebaseTokensSlice, notificationsSlice } from '@thewebops/notifications';
import history from './history';

const rootReducer = combineReducers({
  membershipReducer,
  programReducer,
  nutibitesReducer,
  habitsReducer,
  exercisesReducer,
  inquiryReducer,
  assesmentReducer,
  progressReducer,
  checkups,
  dashboard,
  blogsReducer,
  faqsReducer,
  homeReducer,
  firebaseTokens: firebaseTokensSlice.reducer,
  notifications: notificationsSlice.reducer,
  router: connectRouter(history),
});

export default rootReducer;
