import { CALL_API } from 'middlewares/api';

export const FAQS_REQUEST = 'FAQS_REQUEST';
export const FAQS_SUCCESS = 'FAQS_SUCCESS';
export const FAQS_FAILURE = 'FAQS_FAILURE';

export const fetchFAQS = () => ({
  [CALL_API]: {
    types: [FAQS_REQUEST, FAQS_SUCCESS, FAQS_FAILURE],
    endpoint: `/faqs`,
    customConfig: {
      method: 'GET',
    },
  },
});
