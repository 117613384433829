import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import thunk from 'redux-thunk';
import api from 'middlewares/api';
import logout from 'middlewares/logout';
import reload from 'middlewares/reload';
import rootReducer from 'reducers';

export default function configureStore(initialState = {}, history) {
  const middlewares = [logout, reload, api, thunk, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: true,
        })
      : compose;
  /* eslint-enable */

  const store = createStore(rootReducer, fromJS(initialState), composeEnhancers(...enhancers));

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
}
