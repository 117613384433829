import styled from 'styled-components';
import Colors from 'theme/Colors';
import { Link } from 'react-router-dom';
import Breakpoints from 'theme/Breakpoints';
import tick from 'assets/tick.svg';

export const Title = styled.span`
  width: 100%;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  ${props => (props.bar && props.bar.title ? props.bar.style : '')}
  ${props => (props.paddingTop ? `padding-top : ${props.paddingTop}` : '')}
  ${props => (props.marginTop ? `margin-top : ${props.marginTop}` : '')}
  ${props => (props.marginBottom ? `margin-bottom : ${props.marginBottom}` : '')}
  color: ${Colors.navy};

  @media screen and (max-width: ${Breakpoints.lg}) {
    font-size: 35px;
  }

  @media screen and (max-width: ${Breakpoints.md}) {
    font-size: 30px;
  }

  @media screen and (max-width: ${Breakpoints.sm}) {
    font-size: 25px;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  font-weight: 300;
  word-spacing: 2px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  color: ${Colors.navy};

  ${props => (props.bar && !props.bar.title ? props.bar.style : '')}

  @media screen and (max-width: ${Breakpoints.lg}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${Breakpoints.md}) {
    font-size: 16px;
  }

  @media screen and (max-width: ${Breakpoints.xs}) {
    font-size: 14px;
    font-weight: 300;
  }
`;

export const DescriptiveItem = styled.li`
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.navy};
`;

export const MiniTitle = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.navy};
`;

export const MicroTitle = styled.div`
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.navy};
`;

export const NanoTitle = styled.span`
  font-size: ${props => (props.isBold ? '22px' : '20px')};
  font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.greenishBlue};
  white-space: pre-wrap;
  display: ${props => (props.isHidden ? 'none' : 'block')};
  text-align: center;
  @media screen and (min-width: 1000px) and (max-width: 1024px) {
    font-size: ${props => (props.isBold ? '28px' : '26px')};
    line-height: initial;
  }
`;

export const PicoTitle = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.navy};
  span {
    display: block;
    text-align-last: left;
  }
  @media screen and (min-width: 1000px) and (max-width: 1024px) {
    font-size: 26px;
    line-height: initial;
  }
`;

export const LandingWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  > * {
    min-height: 100vh;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: ${Breakpoints.sm}) {
    margin-top: -13%;
  }
  @media screen and (max-width: ${Breakpoints.xs}) {
    margin-top: -3%;
  }
`;

export const WhiteLink = styled(Link)`
  border-radius: 6px;
  background-color: ${Colors.navy};
  border: hidden;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.white};
`;

export const FormButton = styled.input`
  border-radius: 6px;
  background-color: ${Colors.navy};
  border: hidden;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.white};
`;

export const ActionButton = styled.button`
  border-radius: 6px;
  background-color: ${props => (props.disabled ? Colors.gray : props.background)};
  color: ${props => props.color};
  border: hidden;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  width: 100%;
  cursor: ${props => !props.disabled && 'pointer'};
`;

export const HollowLink = styled(Link)`
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
  border-color: ${Colors.navy}
  text-decoration: none;
  text-align: center;
  padding: 10px;
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.navy};

  @media screen and (max-width: ${Breakpoints.xs}) {
    position: absolute;
    top: 270px;
  }
`;
export const DashboardButton = styled.button`
  padding: 10px 30px;
  background-color: ${Colors.spruce};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 20px;
  border: hidden;
  text-decoration: none;
  cursor: pointer;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  > div {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

export const DashboardContent = styled.div`
  font-family: Lato;
  width: 100%;
  position: relative;

  & img {
    position: relative;
    max-width: 100%;
    margin: 20px auto;
    display: block;
    height: auto;
    float: none !important;
  }

  & * {
    color: ${Colors.spruce};
  }
`;

export const Tick = styled.div`
  background: url(${tick});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;
