import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Colors from 'theme/Colors';
import Breakpoints from 'theme/Breakpoints';

export const HeaderLogoImage = styled(Link)`
  background: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 100%;
  height: 100%;
`;

export const HeaderLogoWrapper = styled.div`
  position: absolute;
  width: 100px;
  height: 100%;
  left: 30px;
  top: 0;
  z-index: 10;
`;

export const NavigationWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  background-color: ${Colors.white};

  @media screen and (max-width: 1100px) {
    position: absolute;
    right: -100%;
    top: 0;
    width: 100%;
    height: 100vh;
    display: block;
  }

  -webkit-transition: transform 0.2s, padding 0.5s ease-in-out;
  -o-transition: transform 0.2s, padding 0.5s ease-in-out;
  transition: transform 0.2s, padding 0.5s ease-in-out;


  ${props =>
    props.isActive
      ? `
  padding-top: 100px;
  -webkit-transform: translateX(-100vw);
  -ms-transform: translateX(-100vw);
  -o-transform: translateX(-100vw);
  transform: translateX(-100vw);
  div {
    position: initial;
    top: initial;
    right: initial;
    height: initial;
    a {
      border-radius: 15px;
      padding: 10px 15px;
    }
  }
  `
      : ``}
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 47.6px;
  padding: 20px 0px;
  top: 0;
  z-index: 100;
  background-color: ${Colors.white};
`;

export const MenuItem = styled(Link)`
  color: ${Colors.navy};
  ${props => (props.route === props.to ? `color: ${Colors.aquamarine}` : ``)};
  margin: auto 10px;
  text-decoration: none;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: auto 30px;

  &:hover {
    color: ${Colors.aquamarine};
  }

  @media screen and (max-width: 1110px) {
    margin: 0 auto;
    font-size: 24px;
    text-align: center;
    display: table;
    margin-bottom: 20px;
    position: relative;
    z-index: 999;
  }
`;

export const SidePanel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  @media screen and (max-width: ${Breakpoints.sm}) {
    top: auto;
    bottom: -100px;
    left: 0;
  }
  > a {
    text-decoration: none;
    background-color: ${Colors.navy};
    color: white;
    padding: 10px;
    padding-left: 20px;
    border-radius: 15px 0px 0px 15px;
    @media screen and (max-width: ${Breakpoints.sm}) {
      border-radius: 15px;
    }
  }
`;

export const SidePanelText = styled.div``;

export const HamburgerWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 40px;
  display: none;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.isActive
      ? `
  top: 45px;
  span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  span:nth-child(2) {
    display: none;
  }

  span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  > span {
    background-color: ${Colors.navy};
    margin: -2.5px 0;
    transition: 0.5s ease all;
  }`
      : ``}



  @media screen and (max-width: 1110px) {
    display: block;
  }
`;

export const HamburgerLine = styled.span`
  width: 35px;
  height: 3px;
  background-color: ${Colors.navy};
  display: block;
  margin: 7px auto;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
`;
