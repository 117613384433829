import { CALL_API } from 'middlewares/api';
import history from 'history.js';
import { fetchProgram } from '../../Program/redux/actions';
import { loadFromLocalStorage } from 'utils/localStorageHelpers';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_FORM_REQUEST = 'RESET_PASSWORD_FORM_REQUEST';
export const RESET_PASSWORD_FORM_SUCCESS = 'RESET_PASSWORD_FORM_SUCCESS';
export const RESET_PASSWORD_FORM_FAILURE = 'RESET_PASSWORD_FORM_FAILURE';
export const CONFIRM_PROFILE_REQUEST = 'CONFIRM_PROFILE_REQUEST';
export const CONFIRM_PROFILE_SUCCESS = 'CONFIRM_PROFILE_SUCCESS';
export const CONFIRM_PROFILE_FAILURE = 'CONFIRM_PROFILE_FAILURE';
export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
export const COMPLETE_PROFILE_REQUEST = 'COMPLETE_PROFILE_REQUEST';
export const COMPLETE_PROFILE_SUCCESS = 'COMPLETE_PROFILE_SUCCESS';
export const COMPLETE_PROFILE_FAILURE = 'COMPLETE_PROFILE_FAILURE';
export const COMPLETE_PAYMENT_REQUEST = 'COMPLETE_PAYMENT_REQUEST';
export const COMPLETE_PAYMENT_SUCCESS = 'COMPLETE_PAYMENT_SUCCESS';
export const COMPLETE_PAYMENT_FAILURE = 'COMPLETE_PAYMENT_FAILURE';
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const HIDE_MODAL = 'HIDE_MODAL';
export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_PAGE_SETTINGS_REQUEST = 'FETCH_PAGE_SETTINGS_REQUEST';
export const FETCH_PAGE_SETTINGS_SUCCESS = 'FETCH_PAGE_SETTINGS_SUCCESS';
export const FETCH_PAGE_SETTINGS_FAILURE = 'FETCH_PAGE_SETTINGS_FAILURE';
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';
export const SET_FIRE_BASE_PERMISSIONS = 'SET_FIRE_BASE_PERMISSIONS';

export const logout = () => {
  const authHeaders = loadFromLocalStorage('authHeaders');
  localStorage.removeItem('authHeaders');
  localStorage.removeItem('currentUser');
  localStorage.removeItem('currentEmail');

  history.replace('/auth/login');
  //window.location.reload();
  return {
    type: 'LOGOUT_SUCCESS',
    payload: { authHeaders },
  };
};

export const checkProgram = () => fetchProgram();

export const changePassword = (passwordParams) => ({
  [CALL_API]: {
    types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE],
    endpoint: `/users/password`,
    authenticated: true,
    customConfig: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(passwordParams),
    },
  },
});

export const fetchPageSettings = () => ({
  [CALL_API]: {
    types: [FETCH_PAGE_SETTINGS_REQUEST, FETCH_PAGE_SETTINGS_SUCCESS, FETCH_PAGE_SETTINGS_FAILURE],
    endpoint: `/page_settings`,
    customConfig: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  },
});

export const editProfile = (userAttributes) => {
  const data = new FormData();
  Object.keys(userAttributes).map((key) => data.append(`user[${key}]`, userAttributes[key]));
  return {
    [CALL_API]: {
      types: [EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAILURE],
      endpoint: `/users/update_self`,
      authenticated: true,
      customConfig: {
        method: 'PUT',
        body: data,
      },
    },
  };
};

export const socialAuthentication = (user, setFirebaseToken) => ({
  [CALL_API]: {
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    endpoint: `/users`,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      query: {
        is_social: true,
      },
      body: JSON.stringify({
        ...user,
      }),
    },
    callbacks: {
      successCallback: loginSuccess,
    },
    callbackArguments: { setFirebaseToken },
  },
});

export const login = (user, setFirebaseToken) => ({
  [CALL_API]: {
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    endpoint: `/users/sign_in`,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...user,
      }),
    },
    callbacks: {
      successCallback: loginSuccess,
    },
    callbackArguments: { setFirebaseToken },
  },
});

export const resetPassword = (email) => ({
  [CALL_API]: {
    types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    endpoint: `/users/passwords`,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    },
  },
});

export const completeProfile = (password, passwordConfirmation, confirmationToken, name, age, phone) => ({
  [CALL_API]: {
    types: [COMPLETE_PROFILE_REQUEST, COMPLETE_PROFILE_SUCCESS, COMPLETE_PROFILE_FAILURE],
    endpoint: `/users/confirmations`,
    customConfig: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        confirmation_token: confirmationToken,
        user: {
          password,
          password_confirmation: passwordConfirmation,
          name,
          age,
          phone_number: phone,
        },
      }),
    },
    callbacks: {
      successCallback: completeProfileSuccess,
    },
  },
});

export const confirmProfile = (confirmationToken) => ({
  [CALL_API]: {
    types: [CONFIRM_PROFILE_REQUEST, CONFIRM_PROFILE_SUCCESS, CONFIRM_PROFILE_FAILURE],
    endpoint: `/users/confirmations/confirm`,
    customConfig: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        confirmation_otp: confirmationToken,
      }),
    },
    callbacks: {
      successCallback: completeProfileSuccess,
      failureCallback: completeProfileFailure,
    },
  },
});

export const confirmEmail = (confirmation_otp) => ({
  [CALL_API]: {
    types: [CONFIRM_EMAIL_REQUEST, CONFIRM_EMAIL_SUCCESS, CONFIRM_EMAIL_FAILURE],
    endpoint: `/users/confirmations/confirm`,
    customConfig: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        confirmation_otp: confirmation_otp,
      }),
    },
    callbacks: {
      successCallback: confirmEmailSuccess,
    },
  },
});
export const completePayment = () => ({
  [CALL_API]: {
    types: [COMPLETE_PAYMENT_REQUEST, COMPLETE_PAYMENT_SUCCESS, COMPLETE_PAYMENT_FAILURE],
    endpoint: '/users/payments',
    authenticated: true,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        integration_type: "card",
      }),
    },
  },
});

export const resetPasswordForm = (password, passwordConfirmation, resetPasswordToken) => ({
  [CALL_API]: {
    types: [RESET_PASSWORD_FORM_REQUEST, RESET_PASSWORD_FORM_SUCCESS, RESET_PASSWORD_FORM_FAILURE],
    endpoint: `/users/passwords`,
    customConfig: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetPasswordToken,
      }),
    },
    callbacks: {
      successCallback: completeProfileSuccess,
    },
  },
});

export const signup = (user) => ({
  [CALL_API]: {
    types: [SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE],
    endpoint: `/users`,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: user.email,
        password: user.password,
        password_confirmation: user.passwordConfirmation,
        program_id: user.programId,
        age: user.age,
        name: user.name,
        phone_number: user.phone,
        promo_code: user.promoCode,
      }),
    },
    callbacks: {
      successCallback: completeSignupSuccess,
    },
  },
});

export const resendOTP = (email) => ({
  [CALL_API]: {
    types: [RESEND_OTP_REQUEST, RESEND_OTP_SUCCESS, RESEND_OTP_FAILURE],
    endpoint: `/users/confirmations`,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    },
  },
});

export const getUserInfo = (confirmationToken) => ({
  [CALL_API]: {
    types: [USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_INFO_FAILURE],
    endpoint: `/users/confirmations?confirmation_token=${confirmationToken}`,
    authenticated: false,
    customConfig: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    callbacks: {
      successCallback: setInvited,
    },
  },
});

const setInvited = () => {
  localStorage.setItem('invited', true);
};

const loginSuccess = ({ response, responseHeaders, callbackArguments }) => {
  const { setFirebaseToken } = callbackArguments;
  const { user } = response;
  const authHeaders = JSON.stringify(responseHeaders);
  const currentUser = JSON.stringify(user);
  localStorage.setItem('authHeaders', authHeaders);
  localStorage.setItem('currentUser', currentUser);
  localStorage.setItem('currentEmail', user.email);
  if (setFirebaseToken) setFirebaseToken();

  const { initial_assesment, payment_completed, email_confirmed } = user;

  return !email_confirmed
    ? history.replace('/confirm-email')
    : payment_completed
    ? user.initial_assesment_completed
      ? history.replace('/progress')
      : history.replace(`/assesment/${initial_assesment ? initial_assesment.step + 1 : '1'}`)
    : history.replace('/complete-payment');
};

const completeProfileSuccess = ({ response, responseHeaders }) => {
  const { user } = response;
  const authHeaders = JSON.stringify(responseHeaders);
  const currentUser = JSON.stringify(user);
  localStorage.setItem('currentUser', currentUser);
  localStorage.setItem('authHeaders', authHeaders);
  if (user.payment_type === 'pre_paid')
    history.replace(`/assesment/${user.initial_assesment ? user.initial_assesment.step + 1 : '1'}`);
  else history.replace('/complete-payment');
};

const confirmEmailSuccess = ({ response, responseHeaders }) => {
  const { user } = response;
  const authHeaders = JSON.stringify(responseHeaders);
  const currentUser = JSON.stringify(user);
  localStorage.setItem('authHeaders', authHeaders);
  localStorage.setItem('currentUser', currentUser);
  localStorage.removeItem('currentEmail');
  history.replace('/complete-payment');
};

const completeSignupSuccess = ({ response, responseHeaders }) => {
  localStorage.setItem('currentEmail', response.user.email);
  localStorage.setItem('timer', true);
  history.replace('/confirm-email');
};

const completeProfileFailure = () => {
  history.replace('/404');
};
export const hideModal = () => ({
  type: HIDE_MODAL,
  payload: {
    isVisible: false,
  },
});

export const fetchUser = () => ({
  [CALL_API]: {
    types: [FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE],
    endpoint: `/users/self`,
    authenticated: true,
    customConfig: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    callbacks: {
      successCallback: fetchUserSuccess,
    },
  },
});

const fetchUserSuccess = ({ response }) => {
  const { user } = response;
  const currentUser = JSON.stringify(user);
  localStorage.setItem('currentUser', currentUser);
};

export const setFireBasePermissions = (payload) => ({
  type: SET_FIRE_BASE_PERMISSIONS,
  payload,
});
