import React from 'react';
import Colors from 'theme/Colors';

const Home = React.lazy(() => import('components/HomePage'));
const About = React.lazy(() => import('components/About'));
const Program = React.lazy(() => import('components/Program'));
const Login = React.lazy(() => import('components/Membership/Login'));
const SignUp = React.lazy(() => import('components/Membership/SignUp'));
const ResetPassword = React.lazy(() => import('components/Membership/ResetPassword'));
const ResetPasswordForm = React.lazy(() => import('components/Membership/ResetPasswordForm'));
const CompleteProfile = React.lazy(() => import('components/Membership/CompleteProfile'));
const ConfirmProfile = React.lazy(() => import('components/Membership/ConfirmProfile'));
const Subscribe = React.lazy(() => import('components/Membership/Subscribe'));
const CompletePayment = React.lazy(() => import('components/Membership/CompletePayment'));
const ConfirmPayment = React.lazy(() => import('components/Membership/ConfirmPayment'));
const Contact = React.lazy(() => import('components/Contact'));
const Assesment = React.lazy(() => import('components/Assesment'));
const Profile = React.lazy(() => import('components/Membership/Profile'));
const EditProfile = React.lazy(() => import('components/Membership/EditProfile'));
const Progress = React.lazy(() => import('components/Progress'));
const Nutibite = React.lazy(() => import('components/Nutibites/Nutibite'));
const SingleNutibite = React.lazy(() => import('components/Nutibites/SingleNutibite'));
const Habit = React.lazy(() => import('components/Habits/Habit'));
const SingleHabit = React.lazy(() => import('components/Habits/SingleHabit'));
const Exercise = React.lazy(() => import('components/Exercises/Exercise'));
const SingleExercise = React.lazy(() => import('components/Exercises/SingleExercise'));
const CheckUp = React.lazy(() => import('components/CheckUp'));
const Chat = React.lazy(() => import('components/Chat'));
const ChangePassword = React.lazy(() => import('components/Membership/ChangePassword'));
const NotStarted = React.lazy(() => import('components/NotStarted'));
const FAQs = React.lazy(() => import('components/FAQs'));
const Blogs = React.lazy(() => import('components/Blogs/Blog'));
const SingleBlog = React.lazy(() => import('components/Blogs/SingleBlog'));
const TermsAndConditions = React.lazy(() => import('components/TermsAndConditions'));
const ConfirmEmail = React.lazy(() => import('components/Membership/ConfirmEmail'));
const CheckUpsHistory = React.lazy(() => import('components/CheckUpsHistory'));

export const routes = [
  { path: '/', container: Home, exact: true },
  { path: '/about', container: About, exact: true },
  { path: '/termsandconditions', container: TermsAndConditions, exact: true },
  { path: '/contact', container: Contact, exact: true },
  { path: '/program', container: Program, exact: true },
  { path: '/auth/login', container: Login, exact: true },
  { path: '/signup', container: SignUp, exact: true },
  { path: '/join-us', container: Subscribe, exact: true },
  { path: '/faqs', container: FAQs, exact: true },
  { path: '/blog', container: Blogs, exact: true },
  { path: '/blog/:blogId', container: SingleBlog, exact: true },
  {
    path: '/complete-payment',
    container: CompletePayment,
    exact: true,
    authenticated: true,
    color: Colors.navy,
  },
  {
    path: '/confirm-payment',
    container: ConfirmPayment,
    exact: true,
    authenticated: true,
    color: Colors.navy,
  },
  {
    path: '/not-started',
    container: NotStarted,
    authenticated: true,
    exact: true,
    color: Colors.navy,
  },
  {
    path: '/confirm-profile/:confirmationToken',
    container: ConfirmProfile,
    exact: true,
  },
  {
    path: '/confirm-email',
    container: ConfirmEmail,
    exact: true,
  },
  { path: '/auth/password', container: ResetPassword, exact: true },
  {
    path: '/users/passwords/:resetPasswordToken',
    container: ResetPasswordForm,
    exact: true,
  },
  {
    path: '/users/confirmations/:confirmationToken',
    container: CompleteProfile,
    exact: true,
  },
  {
    path: '/assesment/:step',
    container: Assesment,
    authenticated: true,
    color: Colors.navy,
  },
  { path: '/profile', container: Profile, exact: true, authenticated: true },
  {
    path: '/editProfile',
    container: EditProfile,
    exact: true,
    authenticated: true,
  },
  {
    path: '/change-password',
    container: ChangePassword,
    exact: true,
    authenticated: true,
  },
  {
    path: '/progress',
    container: Progress,
    exact: true,
    dashboard: {
      visible: true,
      title: 'OVERVIEW',
      progress: false,
    },
    authenticated: true,
    color: Colors.grayishWhite,
  },
  {
    path: '/nutibites',
    container: Nutibite,
    exact: true,
    authenticated: true,
    color: Colors.grayishWhite,
    dashboard: {
      visible: true,
      title: 'NUTIBITES',
      progress: true,
    },
  },
  {
    path: '/nutibites/:nutibiteId',
    container: SingleNutibite,
    exact: true,
    authenticated: true,
    dashboard: {
      visible: true,
      title: 'NUTIBITE',
      progress: true,
      button: {
        link: '/nutibites',
      },
    },
  },
  {
    path: '/habits',
    container: Habit,
    exact: true,
    authenticated: true,
    color: Colors.grayishWhite,
    dashboard: {
      visible: true,
      title: 'HABITS',
      progress: true,
    },
  },
  {
    path: '/habits/:habitId',
    container: SingleHabit,
    exact: true,
    authenticated: true,
    dashboard: {
      visible: true,
      title: 'HABITS',
      progress: true,
      button: {
        link: '/habits',
      },
    },
  },
  {
    path: '/exercises',
    container: Exercise,
    exact: true,
    authenticated: true,
    color: Colors.grayishWhite,
    dashboard: {
      visible: true,
      title: 'EXERCISES',
      progress: true,
    },
  },
  {
    path: '/exercises/:exerciseId',
    container: SingleExercise,
    exact: true,
    authenticated: true,
    dashboard: {
      visible: true,
      title: 'EXERCISE',
      progress: true,
      button: {
        link: '/exercises',
      },
    },
  },
  {
    path: '/check-ups',
    container: CheckUp,
    exact: true,
    authenticated: true,
    dashboard: {
      visible: true,
      title: 'CHECK UP',
      progress: false,
    },
  },
  {
    path: '/check-ups/:id',
    container: CheckUp,
    exact: true,
    authenticated: true,
    dashboard: {
      visible: true,
      title: 'CHECK UP',
      progress: false,
    },
  },
  {
    path: '/check-ups-history',
    container: CheckUpsHistory,
    exact: true,
    authenticated: true,
    dashboard: {
      visible: true,
      title: 'CHECK UP',
      progress: false,
    },
  },
  {
    path: '/chat',
    container: Chat,
    exact: true,
    authenticated: true,
    color: Colors.grayishWhite,
    dashboard: {
      visible: true,
      progress: false,
    },
  },
];

export const privateBank = {
  '/progress': true,
  '/profile': true,
  '/nutibites': true,
  '/habits': true,
  '/exercises': true,
  '/check-ups': true,
  '/check-ups-history': true,
  '/chat': true,
  '/editProfile': true,
  '/change-password': true,
};
