import { Map } from 'immutable';
import { removeItemFromEntries } from 'utils/reducerHelpers';
import {
  CHECKUP_SUBMIT_REQUEST,
  CHECKUP_SUBMIT_SUCCESS,
  CHECKUP_SUBMIT_FAILURE,
  CHECKUP_STATE_REQUEST,
  CHECKUP_STATE_SUCCESS,
  CHECKUP_STATE_FAILURE,
  SINGLE_CHECKUP_REQUEST,
  SINGLE_CHECKUP_SUCCESS,
  SINGLE_CHECKUP_FAILURE,
  HIDE_MODAL,
  RESET_STEP,
} from './actions';

const INITIAL_STATE = Map({
  isFetching: false,
  step: 0,
  modal: { isVisible: false },
  response: {},
  checkups: [],
  checkup: {},
});

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECKUP_SUBMIT_REQUEST:
      return state.set('isFetching', true);
    case CHECKUP_SUBMIT_SUCCESS:
      return state
        .set('isFetching', false)
        .set('step', state.get('step') === 0 && !action.response.checkup.initial ? 1 : 0)
        .set(
          'checkups',
          action.response.checkup.submitted
            ? removeItemFromEntries(state, 'checkups', action.response.checkup.id)
            : state.get('checkups'),
        )
        .set('modal', {
          isVisible: true,
          title:
            state.get('step') === 1 || action.response.checkup.initial
              ? 'The checkup has been submitted successfully'
              : 'Your answers have been saved successfully',
          description:
            state.get('step') === 1 || action.response.checkup.initial
              ? ''
              : 'Please fill out the rest of the checkup questions',
          success: state.get('step') === 1 || action.response.checkup.initial,
        });
    case CHECKUP_SUBMIT_FAILURE:
      return state
        .set('isFetching', false)
        .set('modal', {
          isVisible: true,
          title: 'Missing or wrong values',
          description: 'Please check the fields coloured in red',
        })
        .set('response', action.error);
    case CHECKUP_STATE_REQUEST:
      return state.set('isFetching', true);
    case CHECKUP_STATE_SUCCESS:
      return state.set('isFetching', false).set('checkups', action.response.checkups);
    case CHECKUP_STATE_FAILURE:
      return state.set('isFetching', false);
    case SINGLE_CHECKUP_REQUEST:
      return state.set('isFetching', true);
    case SINGLE_CHECKUP_SUCCESS: {
      const {
        response: { checkup },
      } = action;

      return state.set('isFetching', false).set('checkup', checkup);
    }
    case SINGLE_CHECKUP_FAILURE:
      return state.set('isFetching', false);
    case HIDE_MODAL:
      return state.set('modal', { isVisible: false });
    case RESET_STEP:
      return state.set('step', 0);
    default:
      return state;
  }
};
