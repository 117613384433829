import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import { AppWrapper, RouterWrapper } from './components/Styled';
import Router from './router';
import { privateBank } from './constants';
import DashboardHeader from '../DashboardHeader';
import ScrollToTop from 'react-router-scroll-top';
import { Helmet } from 'react-helmet';
import CustomerChat from 'components/Messenger/CustomerChat.js';
import { bindActionCreators } from 'redux';
import { setFireBasePermissions } from 'components/Membership/redux/actions';
import 'utils/notifications';
import { useFirebasePermissions } from '@thewebops/notifications';

const App = ({ location, currentUser, setFireBasePermissions }) => {
  const pathName = Object.keys(privateBank).find((key) => location.pathname.includes(key));
  const { setFirebaseToken, deleteDeviceToken } = useFirebasePermissions();

  useEffect(() => {
    if (currentUser) setFirebaseToken();

    setFireBasePermissions({ setFirebaseToken, deleteDeviceToken });
  }, []);

  return (
    <AppWrapper private={pathName}>
      <Helmet titleTemplate="Nutivate | %s">
        <title>Habits Not Diets</title>
        <meta
          name="description"
          content="GET IN SHAPE AND CHANGE YOUR LIFESTYLE WITH SMALL, MAINTAINABLE , EASY STEPS."
        />
        <meta itemprop="name" content="Nutivate" />
        <meta
          itemprop="description"
          content="GET IN SHAPE AND CHANGE YOUR LIFESTYLE WITH SMALL, MAINTAINABLE , EASY STEPS."
        />
        <meta itemprop="image" content="https://nutivate.com/back.jpg" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Nutivate" />
        <meta
          property="og:description"
          content="GET IN SHAPE AND CHANGE YOUR LIFESTYLE WITH SMALL, MAINTAINABLE , EASY STEPS."
        />
        <meta property="og:image" content="https://nutivate.com/back.jpg" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nutivate" />
        <meta
          name="twitter:description"
          content="GET IN SHAPE AND CHANGE YOUR LIFESTYLE WITH SMALL, MAINTAINABLE , EASY STEPS."
        />
        <meta name="twitter:image" content="https://nutivate.com/back.jpg" />
        <meta name="robots" content="index, follow" />
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta
          name="keywords"
          content="Nutivate, Fitness Egypt, Fitness pall, nutrition, nutrition egypt, shady labib, Habits, habits Not Diets, WebOps"
        />
        <meta property="og:type" content="website" />
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <RouterWrapper padd={!pathName}>
        <ScrollToTop>
          <Router />
        </ScrollToTop>
      </RouterWrapper>

      {!pathName ? (
        <div>
          <Header location={location} />
          <Footer location={location} />
          <CustomerChat />
        </div>
      ) : (
        <Fragment>
          {currentUser && <Sidebar currentUser={currentUser} location={location} />}
          <DashboardHeader />
        </Fragment>
      )}
    </AppWrapper>
  );
};

App.propTypes = {
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    currentUser: state.getIn(['membershipReducer', 'currentUser']),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setFireBasePermissions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
