import { CALL_API } from 'middlewares/api';

export const SINGLE_NUTIBITE_REQUEST = 'SINGLE_NUTIBITE_REQUEST';
export const SINGLE_NUTIBITE_SUCCESS = 'SINGLE_NUTIBITE_SUCCESS';
export const SINGLE_NUTIBITE_FAILURE = 'SINGLE_NUTIBITE_FAILURE';
export const NUTIBITES_REQUEST = 'NUTIBITES_REQUEST';
export const NUTIBITES_SUCCESS = 'NUTIBITES_SUCCESS';
export const NUTIBITES_FAILURE = 'NUTIBITES_FAILURE';
export const NUTIBITE_ANSWER_REQUEST = 'NUTIBITE_ANSWER_REQUEST';
export const NUTIBITE_ANSWER_SUCCESS = 'NUTIBITE_ANSWER_SUCCESS';
export const NUTIBITE_ANSWER_FAILURE = 'NUTIBITE_ANSWER_FAILURE';
export const HIDE_MODAL = '  HIDE_MODAL';

export const fetchNutibites = () => ({
  [CALL_API]: {
    types: [NUTIBITES_REQUEST, NUTIBITES_SUCCESS, NUTIBITES_FAILURE],
    endpoint: `/users/lessons`,
    authenticated: true,
    customConfig: {
      method: 'GET',
    },
  },
});

export const fetchNutibiteRequest = nutibiteId => ({
  [CALL_API]: {
    types: [
      SINGLE_NUTIBITE_REQUEST,
      SINGLE_NUTIBITE_SUCCESS,
      SINGLE_NUTIBITE_FAILURE,
    ],
    endpoint: `/users/lessons/${nutibiteId}`,
    authenticated: true,
    customConfig: {
      method: 'GET',
    },
  },
});

export const submitNutibiteRequest = nutibiteParams => ({
  [CALL_API]: {
    types: [
      NUTIBITE_ANSWER_REQUEST,
      NUTIBITE_ANSWER_SUCCESS,
      NUTIBITE_ANSWER_FAILURE,
    ],
    endpoint: `/users/answers`,
    authenticated: true,
    customConfig: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ answer: nutibiteParams }),
    },
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
  payload: {
    isVisible: false,
  },
});
