import styled from 'styled-components';
import Breakpoints from 'theme/Breakpoints';
import Colors from 'theme/Colors';
import { Title } from 'components/Global/Styled';
import { Link } from 'react-router-dom';

export const DashboardHeaderWrapper = styled.div`
  display: flex;
  jusity-content: center;
  align-items: center;

  flex-direction: row;

  padding-right: 20px;
  padding-top: 20px;
  box-sizing: border-box;

  position: relative;

  ${Title} {
    text-align: left;
  }

  @media screen and (max-width: ${Breakpoints.sm}) {
    padding-top: 0;

    ${Title} {
      font-size: 20px;
      padding-top: 20px;
    }
  }

  @media screen and (max-width: ${Breakpoints.xs}) {
    padding-top: 0;

    ${Title} {
      font-size: 20px;
      padding-top: 20px;
    }
  }
`;

export const ProgressWrapper = styled.div`
  width: 120px;
  align-self: flex-end;

  > * > * > * {
    font-size: 13px;
  }

  @media screen and (max-width: ${Breakpoints.xs}) {
    width: 100px;

    > * > * > * {
      font-size: 10px;
    }
  }
`;

export const DashboardHeaderButton = styled(Link)`
  padding: 5px 10px;
  background-color: ${Colors.spruce};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 20px;
  border: hidden;
  text-decoration: none;
  cursor: pointer;

  color: ${Colors.white};
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  @media screen and (max-width: ${Breakpoints.sm}) {
    font-size: 13px;
    margin-top: 100px;
  }
`;

export const DashboardLeftWrapper = styled.div`
  align-self: baseline;
  width: 100%;
  height: 100%;

  ${Title} {
    margin-bottom: ${props => (props.button ? '10px' : 0)};
  }
`;
