/* eslint-disable no-param-reassign */
import moment from 'moment';

export const removeItemFromEntries = (state, key, itemId) => state.get(key).filter(v => v.id !== itemId);

export const updateItemsInEntries = (state, newItems, itemId) => {
  const itemIndex = state.get('entries').findIndex(el => el.id === itemId);
  return state.get('entries').map((elm, i) => (i === itemIndex ? { ...elm, ...newItems } : elm));
};

export const dateReduce = array => {
  const reduced = array.reduce((obj, item) => {
    const { due_date: date } = item;

    const startDate = moment(date).format('MMMM');

    if (!obj[startDate]) obj[startDate] = [];
    obj[startDate].push(item);

    return obj;
  }, {});
  return reduced;
};
