import React from 'react';
import { PicoTitle } from 'components/Global/Styled';
import PropTypes from 'prop-types';
import { AvatarWrapper, SidebarAvatarWrapper } from './Styled';

const Avatar = ({ image, name, route, onClick }) => (
  <AvatarWrapper onClick={onClick} to="/profile" route={route}>
    <SidebarAvatarWrapper image={image} />
    <PicoTitle>{name}</PicoTitle>
  </AvatarWrapper>
);

Avatar.propTypes = {
  image: PropTypes.any,
  name: PropTypes.string,
  route: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Avatar;
