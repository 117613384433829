import { API_BASE_URL } from 'utils/constants';
import { notificationsConfigs } from '@thewebops/notifications';

const {
  REACT_APP_FIREBASE_KEY = 'AIzaSyCvDlRuFnZ9ocrFNIp9RLwrlUjWiOvdROU',
  REACT_APP_AUTH_DOMAIN = 'guc-pocket.firebaseapp.com',
  REACT_APP_PROJECT_ID = 'guc-pocket',
  REACT_APP_STORAGE_BUCKET = 'guc-pocket.appspot.com',
  REACT_APP_SENDER_ID = 761405181981,
  REACT_APP_DATABASE = 'https://lirten-hub.firebaseio.com',
  REACT_APP_FIREBASE_APP_ID = '1:761405181981:web:0e41d93634469ba046f6f4',
  REACT_APP_FIREBASE_VAPID_KEY = 'BO2ZmHQfmq_lvmt0kO8TI-HKLkHEt_MID6e0GHXlNwkF6NaKh1o4B6H2r7d-5F601me0DIJpHp9KSFZzCaQW2p8',
} = process.env;

const notificationConfigs = {
  baseURL: `${API_BASE_URL}/users`,
  enableThunks: true,
  enableMapSelectors: true,
  authHeaders: 'authHeaders',
  firebaseConfig: {
    apiKey: REACT_APP_FIREBASE_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    databaseURL: REACT_APP_DATABASE,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
  },
};

notificationsConfigs.initialize(notificationConfigs);
