import { Map } from 'immutable';
import {
  FAQS_REQUEST,
  FAQS_SUCCESS,
  FAQS_FAILURE,
} from './actions';

const INITIAL_STATE = Map({
  isFetching: false,
  faqs: [],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FAQS_REQUEST:
      return state.set('isFetching', true);
    case FAQS_SUCCESS: {
      const {
        response: { faqs },
      } = action;

      return state
        .set('isFetching', false)
        .set('faqs', faqs);
    }
    case FAQS_FAILURE:
      return state.set('isFetching', false);

    default:
      return state;
  }
};
