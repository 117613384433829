/* eslint-disable no-param-reassign */
import { Map } from 'immutable';
import { PROGRESS_REQUEST, PROGRESS_SUCCESS, PROGRESS_FAILURE } from './actions';

const INITIAL_STATE = Map({
  isFetching: true,
  lesson_progress: {},
  habit_progress: {},
  exercise_progress: {},
  modal: { isVisible: false },
});

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROGRESS_REQUEST:
      return state.set('isFetching', true).set('modal', { isVisible: false });
    case PROGRESS_SUCCESS: {
      const {
        response: {
          exercises,
          habits,
          checkup,
          lesson_progress: lessonProgress,
          habit_progress: habitProgress,
          exercise_progress: exerciseProgress,
        },
      } = action;

      const exerciseDates = exercises.reduce((obj, item) => {
        if (item.answered) obj[item.due_date] = true;
        return obj;
      }, {});

      const habitDates = habits.reduce((obj, item) => {
        if (item.answered) obj[item.due_date] = true;
        return obj;
      }, {});

      const weight = checkup.progress.weight.reduce((array, item) => {
        array.push({ date: item[0], count: item[1] });
        return array;
      }, []);

      const hips = checkup.progress.hips.reduce((array, item) => {
        array.push({ date: item[0], count: item[1] });
        return array;
      }, []);

      const waist = checkup.progress.waist.reduce((array, item) => {
        array.push({ date: item[0], count: item[1] });
        return array;
      }, []);

      const nutibitesPercentage = lessonProgress.no_of_answered_lessons / lessonProgress.no_of_lessons || 0;
      const habitsPercentage = habitProgress.no_of_answered_habits / habitProgress.no_of_habits || 0;
      const exercisesPercentage = exerciseProgress.no_of_answered_exercises / exerciseProgress.no_of_exercises || 0;
      const consistencyPercentage = nutibitesPercentage * 0.1 + habitsPercentage * 0.6 + exercisesPercentage * 0.3;

      return state
        .set('isFetching', false)
        .set('nutibitesPercentage', nutibitesPercentage * 100)
        .set('habitsPercentage', habitsPercentage * 100)
        .set('exercisesPercentage', exercisesPercentage * 100)
        .set('consistencyPercentage', consistencyPercentage * 100)
        .set('exerciseDates', exerciseDates)
        .set('habitDates', habitDates)
        .set('checkup', checkup)
        .set('weight', weight)
        .set('hips', hips)
        .set('waist', waist);
    }
    case PROGRESS_FAILURE:
      return state.set('isFetching', false);
    default:
      return state;
  }
};
