import styled from 'styled-components';
import Colors from 'theme/Colors';
import { NavLink, Link } from 'react-router-dom';
import Breakpoints from 'theme/Breakpoints';

export const Wrapper = styled.div`
  position: fixed;

  display: inline;
  width: 200px;
  height: calc(100vh - 60px);
  border-radius: 0 10px 10px 0;
  padding: 30px;
  background-color: ${Colors.white};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin-bottom: 40px;
  }

  > :last-child {
    margin-bottom: 0;
  }

  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

  transition: box-shadow 0.3s ease-in-out;

  @media screen and (max-width: ${Breakpoints.sm}) {
    position: absolute;
    left: -300px;
  }
`;

export const SidebarImage = styled(NavLink)`
  background: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 100%;
`;

export const SidebarLogoWrapper = styled.div`
  background: url(${props => props.image});
  width: 101px;
  height: 47.6px;
  margin: 0;
  margin-bottom: 30px;
  margin-left: 25%;
`;

export const Box = styled.div`
  font-size: 15px;
  color: ${Colors.spruce};
  font-family: Lato;
  text-align: center;
  margin-bottom: 100px;
`;

export const SidebarAvatarWrapper = styled.div`
  background: url(${props => props.image});
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  position: relative;
  left: 0;
  right: 0;
`;

export const SidebarButton = styled(NavLink)`
  color: #0b4a47;
  border-radius: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 52px;
  position: relative;
  margin-top: 5px;
  transition: all 0.1s linear;

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${Colors.spruce};
    > * {
      color: ${Colors.white} !important;
    }
  }

  ${props =>
    props.route === props.to
      ? `
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${Colors.spruce};
    > * {
      color: ${Colors.white} !important;
    }
  `
      : `
      > * {
        color: ${Colors.spruce};
      }`};

  padding: 5px 15px;
  @media screen and (max-width: ${Breakpoints.sm}) {
    margin-top: 10px;
  }
`;

export const SidebarButtonImage = styled.img`
  max-width: 40px;

  @media screen and (max-width: ${Breakpoints.sm}) {
    max-height: 20px;
  }
`;

export const SidebarButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const AvatarWrapper = styled(Link)`
  width: 100%;

  > * {
    margin: 0 auto;
    text-align: center;
  }

  padding: 20px 0;

  > :first-child {
    margin-bottom: 10px;
  }

  border-radius: 10px;
  text-decoration: none;

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${Colors.spruce};
    > * {
      color: ${Colors.white} !important;
    }
  }

  @media screen and (max-width: ${Breakpoints.sm}) {
    padding: 10px 0;
  }

  ${props =>
    props.route === props.to
      ? `
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${Colors.spruce};
    > * {
      color: ${Colors.white} !important;
    }
  `
      : `
      > * {
        color: ${Colors.spruce};
      }`};
`;

export const HeaderLogoImage = styled(Link)`
  background: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 100%;
  height: 100%;
`;

export const HeaderLogoWrapper = styled.div`
  position: absolute;
  width: 101px;
  height: 47.6px;
  left: 40px;
`;

export const NavigationWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${Breakpoints.sm}) {
    position: absolute;
    left: -200px;
    width: 200px;
    top: 0;
    height: calc(100vh - 60px);
    padding: 30px;
    background-color: ${Colors.white};
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  -webkit-transition: transform 0.2s, padding 0.5s ease-in-out;
  -o-transition: transform 0.2s, padding 0.5s ease-in-out;
  transition: transform 0.2s, padding 0.5s ease-in-out;

  ${props =>
    props.isActive
      ? `
  -webkit-transform: translateX(500px);
  -ms-transform: translateX(500px);
  -o-transform: translateX(500px);
  transform: translateX(500px);`
      : ``}
`;

export const HeaderWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 47.6px;
  padding: 20px 0px;
  top: 0;
  z-index: 100;
  background-color: ${Colors.white};
`;

export const MenuItem = styled(Link)`
  color: ${Colors.white};
  margin: auto 10px;
  text-decoration: none;
  font-family: Lato;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  &::after {
    display: block;
    content: '';
    border-bottom-color: ${Colors.aquamarine};
    border-bottom-width: 2px;
    border-bottom-style: solid;
    transform-origin: 0% 50%;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    ${props => (props.route === props.to ? `transform: scaleX(1)` : ``)};
  }

  &:hover:after {
    transform: scaleX(1);
  }

  @media screen and (max-width: ${Breakpoints.sm}) {
    margin: 0 auto;
    text-align: center;
    display: table;
    margin-bottom: 30px;
  }
`;

export const SidePanel = styled.div`
  height: 100vh;
  width: 5%;
  position: absolute;
  top: 0;
  right: 0;

  padding: 40px 20px;
  display: grid;
  grid-template-columns: 40px;
  grid-template-rows: 150px 80px 80px;
  justify-content: center;

  @media screen and (max-width: ${Breakpoints.sm}) {
    position: relative;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    grid-template-rows: 100%;
    height: 40px;
    width: 80%;
    margin: 40px auto;
  }

  background-color: ${Colors.navy};
`;

export const SidePanelImage = styled.div`
  background: url(${props => props.source});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
`;

export const HamburgerWrapper = styled.div`
  position: relative;
  display: none;
  z-index: 1000;

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.isActive
      ? `
  span:nth-child(1) {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }

  span:nth-child(3) {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  > span {
    background-color: ${Colors.spruce};
  }`
      : ``}



  @media screen and (max-width: ${Breakpoints.sm}) {
    display: block;
  }
`;

export const HamburgerLine = styled.span`
  width: 35px;
  height: 3px;
  background-color: ${Colors.spruce};
  display: block;
  margin: 7px auto;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
`;

export const NotificationDot = styled.span`
  display: ${props => (props.count === 0 ? 'none' : 'inline-flex')};
  background-color: ${Colors.lightRed};
  width: 20px;
  height: 20px;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: ${Colors.white};
  right: 10px;
  top: 10px;
  position: absolute;
`;
