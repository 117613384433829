import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SidePanelText } from './Styled';

const SidePanelLink = ({ route, onClick }) => (
  <Fragment>
    <Link to={route} onClick={onClick}>
      <SidePanelText>SIGN UP</SidePanelText>
    </Link>
  </Fragment>
);

SidePanelLink.propTypes = {
  route: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default SidePanelLink;
