export default (store) => (next) => (action) => {
  const reloadableActions = [
    'LOGOUT_SUCCESS',
    'firebaseTokens/deleteFirebaseToken/fulfilled',
    'firebaseTokens/deleteFirebaseToken/rejected ',
  ];
  if (!reloadableActions.includes(action.type) || action.payload?.deleteToken) return next(action);

  window.location.reload();
};
