import { Map } from 'immutable';
import { dateReduce } from 'utils/reducerHelpers';
import {
  EXERCISES_REQUEST,
  EXERCISES_SUCCESS,
  EXERCISES_FAILURE,
  SINGLE_EXERCISE_REQUEST,
  SINGLE_EXERCISE_SUCCESS,
  SINGLE_EXERCISE_FAILURE,
  EXERCISE_ANSWER_REQUEST,
  EXERCISE_ANSWER_SUCCESS,
  EXERCISE_ANSWER_FAILURE,
  HIDE_MODAL,
} from './actions';

const INITIAL_STATE = Map({
  isFetching: false,
  exercise: {},
  modal: { isVisible: false },
});

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXERCISES_REQUEST:
      return state.set('isFetching', true);
    case EXERCISES_SUCCESS: {
      const {
        response: { exercises },
      } = action;

      return state
        .set('isFetching', false)
        .set('exercises', dateReduce(exercises));
    }
    case EXERCISES_FAILURE:
      return state.set('isFetching', false);
    case SINGLE_EXERCISE_REQUEST:
      return state.set('isFetching', true);
    case SINGLE_EXERCISE_SUCCESS:
      return state
        .set('isFetching', false)
        .set('exercise', action.response.exercise);
    case SINGLE_EXERCISE_FAILURE:
      return state.set('isFetching', false);
    case EXERCISE_ANSWER_REQUEST:
      return state.set('isFetching', true).set('modal', { isVisible: false });
    case EXERCISE_ANSWER_SUCCESS:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Answer Submitted',
      });
    case EXERCISE_ANSWER_FAILURE:
      return state.set('isFetching', false).set('modal', {
        isVisible: true,
        title: 'Something went wrong',
      });
    case HIDE_MODAL:
      return state.set('modal', { isVisible: false });
    default:
      return state;
  }
};
