import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavigationWrapper, FooterWrapper, MenuItem, SocialMediaWrapper, CopyRightWrapper } from './components/Styled';
import { leftLinks, rightLinks } from './constants';
import facebook from 'assets/facebook.png';
import instagram from 'assets/instagram.png';

class Footer extends React.Component {
  render() {
    const { location, currentUser } = this.props;

    return (
      <FooterWrapper>
        <NavigationWrapper>
          {leftLinks.map(({ name, route }) => (
            <MenuItem key={name} to={route} route={location.pathname} onClick={this.hideMenu}>
              {name}
            </MenuItem>
          ))}
        </NavigationWrapper>
        <NavigationWrapper>
          {rightLinks(currentUser).map(({ name, route }) => (
            <MenuItem key={name} to={route} route={location.pathname} onClick={this.hideMenu}>
              {name}
            </MenuItem>
          ))}
        </NavigationWrapper>
        <SocialMediaWrapper>
          Follow us on:
          <a href="https://www.facebook.com/nutivate/" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/nutivate/" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="Instagram" />
          </a>
        </SocialMediaWrapper>
        <CopyRightWrapper>
          © 2020 Nutivate All rights reserved <br/>|
          <span>
            &nbsp;&nbsp;Designed and developed by
            <a rel="noopener noreferrer" target="_blank" href="https://thewebops.com">
              &nbsp;&nbsp;WebOps
            </a>
          </span>
        </CopyRightWrapper>
      </FooterWrapper>
    );
  }
}

Footer.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.getIn(['membershipReducer', 'currentUser']),
  };
}

export default connect(mapStateToProps)(Footer);
