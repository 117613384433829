import React, { Suspense } from "react";
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import { LoaderContainer, PageLoaderChildrenWrapper } from './Styled';
import Colors from 'theme/Colors';


const Loader = ({ children }) => {
  return(
    <Suspense
      fallback={
        <LoaderContainer>
          <ReactLoading type="spinningBubbles" color={Colors.spruce} height={100} width={100} />
        </LoaderContainer>
      }
    >
    <PageLoaderChildrenWrapper>{children}</PageLoaderChildrenWrapper>
  </Suspense>
  )

}

Loader.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Loader;
