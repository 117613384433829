import React from 'react';
import Logo from 'assets/logo.svg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  HeaderLogoImage,
  HeaderLogoWrapper,
  NavigationWrapper,
  HeaderWrapper,
  MenuItem,
  SidePanel,
} from './components/Styled';
import { links, sideLinks } from './constants';
import Hamburger from './components/Hamburger';
import SidePanelLink from './components/SidePanelLink';

class Header extends React.Component {
  constructor() {
    super();

    this.state = {
      sideMenu: false,
    };

    this.sideMenu = this.sideMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    if (window.innerWidth > 1110) {
      this.setState({ sideMenu: false });
    }
  };

  sideMenu = e => {
    e.preventDefault();
    const { sideMenu } = this.state;
    this.setState({ sideMenu: !sideMenu });
  };

  hideMenu = () => {
    this.setState({ sideMenu: false });
  };

  render() {
    const { location, currentUser } = this.props;
    const { sideMenu } = this.state;

    return (
      <HeaderWrapper>
        <HeaderLogoWrapper>
          <HeaderLogoImage to="/" image={Logo} />
        </HeaderLogoWrapper>

        <Hamburger isActive={sideMenu} click={this.sideMenu} />

        <NavigationWrapper isActive={sideMenu}>
          {links(currentUser).map(({ name, route }) => (
            <MenuItem key={name} to={route} route={location.pathname} onClick={this.hideMenu}>
              {name.toUpperCase()}
            </MenuItem>
          ))}

          <SidePanel>
            {sideLinks(currentUser).map(link => (
              <SidePanelLink {...link} key={link.route} onClick={this.hideMenu} />
            ))}
          </SidePanel>
        </NavigationWrapper>
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.getIn(['membershipReducer', 'currentUser']),
  };
}

export default connect(mapStateToProps)(Header);
