import styled from 'styled-components';

export const Circle = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-linecap: round;
`;

export const RadialProgressWrapper = styled.div`
  position: relative;
  width: fit-content;

  align-items: center;
  display: flex;
  justify-content: center;

  height: auto;
`;

export const TextWrapper = styled.div`
  width: fit-content;
  position: absolute;
  text-align: center;
  > :nth-child(1) {
    margin-bottom: 5px;
  }
`;
