import React from 'react';
import PropTypes from 'prop-types';
import { HamburgerWrapper, HamburgerLine } from './Styled';

const Hamburger = ({ isActive, click }) => (
  <HamburgerWrapper onClick={click} isActive={isActive}>
    <HamburgerLine />
    <HamburgerLine />
    <HamburgerLine />
  </HamburgerWrapper>
);

Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  click: PropTypes.func.isRequired,
};

export default Hamburger;
