
export default {
  white: '#ffffff',
  grayishWhite: '#eee7df',
  navy: '#002e3a',
  hotPink: '#ff0086',
  blueViolet: '#6e00d8',
  sunflowerYellow: '#ffd008',
  salmon: '#ff7272',
  azul: '#2f80ed',
  darkRed: '#ff0000',
  spruce: '#0b4a47',
  neonBlue: '#0bddff',
  lightRed: '#f9432b',
  blue: '#0085ff',
  aquamarine: '#00e2b7',
  greyishBrown: '#4c4c4c',
  pinkishGrey: '#c4c4c4',
  black: '#000000',
  transparentNavy: 'rgba(0, 37, 50, 0.82)',
  neonGreen: '#60fecf',
  gray: '#bab8b8',
  midGray: '#626262',
  lightBlue: 'rgba(9,206,170,0.15)',
  DarkerlightBlue: 'rgba(206,246,237,0.42)',
  hightOpacityDarkerlightBlue: 'rgba(206,246,237,0.59)',
  greenishBlue: '#007771',
  mintGreen: '#09ceaa',
  lightMintGreen: 'rgba(9,206,170,0.15)',
  lightGray: '#fbfbfb',
  lighterGray: '#f8f7f7',
  gray1: '#999999',
  grya2: '#d8d8d8',
  red: '#f10b12',
};
