import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import App from 'components/App';
import history from './history';
import configureStore from './configureStore';
import register, { unregister } from './registerServiceWorker';
import 'normalize.css';
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

if (process.env.NODE_ENV === 'development') {
  register();
  ReactDOM.render(<Root />, MOUNT_NODE);
  if (module.hot) {
    module.hot.accept('components/App', () => {
      ReactDOM.render(<Root />, MOUNT_NODE);
    });
  }
} else {
  unregister();
  ReactDOM.render(<Root />, MOUNT_NODE);
}
