import moment from 'moment';

export const leftLinks = [
  {
    name: 'Home',
    route: '/',
  },
  {
    name: 'About us',
    route: '/about',
  },
  {
    name: 'The Program',
    route: '/program',
  },
  {
    name: 'Contact us',
    route: '/contact',
  },
];

export const rightLinks = currentUser => {
  let userLink;

  if (currentUser) {
    if (!currentUser.email_confirmed ) userLink = '/confirm-email';
    else if (!currentUser.payment_completed) userLink = '/complete-payment';
    else if (!currentUser.initial_assesment_completed)
      userLink = `/assesment/${currentUser.initial_assesment ? currentUser.initial_assesment.step + 1 : '1'}`;
    else if (moment().diff(moment(currentUser.program.start_date), 'hours') <= 0) userLink = `/not-started`;
    else userLink = `/progress`;
  } else userLink = '/auth/login';

  return [
    {
      name: 'Log in',
      route: userLink,
    },
    {
      name: 'Blog',
      route: '/blog',
    },
    {
      name: "FAQ's",
      route: '/faqs',
    },
    {
      name: 'Terms & Conditions',
      route: '/termsandconditions',
    },
  ];
};
