import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Colors from 'theme/Colors';
import Breakpoints from 'theme/Breakpoints';

export const FooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.DarkerlightBlue};
  clear: both;
  position: relative;
`;

export const NavigationWrapper = styled.div`
  display: inline-block;
  margin: 20px 50px;
  vertical-align:top;
  @media screen and (max-width: ${Breakpoints.sm}) {
    margin: 20px;
  }
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  right: 30%;
  top: 0;
  bottom: 0;
  @media screen and (max-width: ${Breakpoints.sm}) {
    text-align: center;
    position: relative;
    right: auto;
  }
  img {
    max-width: 25px;
    max-height: 25px;
    margin: 15px;
  }
`;

export const MenuItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${Colors.navy};
  margin: 10px;
  ${props => (props.route === props.to ? `color: ${Colors.aquamarine}; ` : ``)};
  @media screen and (max-width: ${Breakpoints.sm}) {
    margin: 20px 10px;
  }
`;

export const CopyRightWrapper = styled.div`
  background-color: ${Colors.hightOpacityDarkerlightBlue};
  text-align: center;
  padding: 10px 0;
  z-index: 10;
  position: relative;
  @media screen and (max-width: ${Breakpoints.sm}) {
    font-size: 11px;
    line-height: 15px;
    padding: 7px 0;
  }
  span {
    font-size: 14px;
    @media screen and (max-width: ${Breakpoints.sm}) {
      font-size: 7px;
      margin-top: 7px;
    }
  }
  a {
    color: ${Colors.navy};
    text-decoration: none;
    cursor: pointer;
    z-index: 10;
  }
`;
