import React from 'react';
import { NotFoundContainer } from './components/Styled';
import { Helmet } from 'react-helmet';

const NotFound = () => (
  <NotFoundContainer>
    <Helmet>
      <title>Page Not Found</title>
      <meta property="og:title" content="Nutivate | Page Not Found" />
    </Helmet>
    <h1>
      404 <br /> Oops! The page you requested was not found
    </h1>
  </NotFoundContainer>
);

export default NotFound;
