import { Map } from 'immutable';
import { ASSESMENT_STEP_REQUEST, ASSESMENT_STEP_SUCCESS, ASSESMENT_STEP_FAILURE, HIDE_MODAL } from './actions';

const INITIAL_STATE = Map({ isFetching: false, modal: { isVisible: false }, response: {} });

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ASSESMENT_STEP_REQUEST:
      return state.set('isFetching', true);
    case ASSESMENT_STEP_SUCCESS:
      return state.set('isFetching', false);
    case ASSESMENT_STEP_FAILURE:
      return state
        .set('isFetching', false)
        .set('modal', {
          isVisible: true,
          title: 'Missing Values',
          description: 'Please provide all the required fields',
        })
        .set('response', action.error);
    case HIDE_MODAL:
      return state.set('modal', { isVisible: false });
    default:
      return state;
  }
};
