import { CALL_API } from 'middlewares/api';
export const CHECKUP_SUBMIT_REQUEST = 'CHECKUP_SUBMIT_REQUEST';
export const CHECKUP_SUBMIT_SUCCESS = 'CHECKUP_SUBMIT_SUCESS';
export const CHECKUP_SUBMIT_FAILURE = 'CHECKUP_SUBMIT_FAILURE';

export const CHECKUP_STATE_REQUEST = 'CHECKUP_STATE_REQUEST';
export const CHECKUP_STATE_SUCCESS = 'CHECKUP_STATE_SUCESS';
export const CHECKUP_STATE_FAILURE = 'CHECKUP_STATE_FAILURE';

export const SINGLE_CHECKUP_REQUEST = 'SINGLE_CHECKUP_REQUEST';
export const SINGLE_CHECKUP_SUCCESS = 'SINGLE_CHECKUP_SUCCESS';
export const SINGLE_CHECKUP_FAILURE = 'SINGLE_CHECKUP_FAILURE';

export const HIDE_MODAL = 'HIDE_MODAL';
export const RESET_STEP = 'RESET_STEP';

export const submitCheck = (id, step, answers) => {
  const data = new FormData();
  data.append('checkup[step]', step);

  Object.keys(answers).map(key => data.append(`checkup[${key}]`, answers[key]));
  return {
    [CALL_API]: {
      types: [CHECKUP_SUBMIT_REQUEST, CHECKUP_SUBMIT_SUCCESS, CHECKUP_SUBMIT_FAILURE],
      endpoint: `/users/checkups/${id}`,
      authenticated: true,
      customConfig: {
        method: 'PUT',
        body: data,
      },
    },
  };
};

export const checkupState = (submitted = false) => ({
  [CALL_API]: {
    types: [CHECKUP_STATE_REQUEST, CHECKUP_STATE_SUCCESS, CHECKUP_STATE_FAILURE],
    endpoint: `/users/checkups?submitted=${submitted}`,
    authenticated: true,
    customConfig: {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    },
  },
});

export const fetchCheckup = checkupId => ({
  [CALL_API]: {
    types: [SINGLE_CHECKUP_REQUEST, SINGLE_CHECKUP_SUCCESS, SINGLE_CHECKUP_FAILURE],
    endpoint: `/users/checkups/${checkupId}`,
    authenticated: true,
    customConfig: {
      method: 'GET',
    },
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
  payload: {
    isVisible: false,
  },
});

export const resetStep = () => ({
  type: RESET_STEP,
});
