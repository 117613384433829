import React, { Fragment } from 'react';
import avatar from 'assets/avatar.png';
import logo from 'assets/logo.svg';
import { NanoTitle } from 'components/Global/Styled';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUser } from 'components/Membership/redux/actions';
import Hamburger from './components/Hamburger';
import {
  Wrapper,
  SidebarLogoWrapper,
  SidebarButton,
  SidebarButtonImage,
  SidebarButtonsWrapper,
  NavigationWrapper,
  NotificationDot,
} from './components/Styled';
import { links } from './constants';
import Avatar from './components/Avatar';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sideMenu: false,
    };

    this.sideMenu = this.sideMenu.bind(this);
  }

  componentDidMount() {
    const { userRequest } = this.props;
    userRequest();

    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    if (window.innerWidth > 767) {
      this.setState({ sideMenu: false });
    }
  };

  sideMenu = e => {
    e.preventDefault();
    const { sideMenu } = this.state;
    this.setState({ sideMenu: !sideMenu });
  };

  hideMenu = () => {
    this.setState({ sideMenu: false });
  };

  render() {
    const { currentUser, location } = this.props;
    const { sideMenu } = this.state;

    return (
      <Fragment>
        <Wrapper>
          <NavigationWrapper isActive={sideMenu}>
            <SidebarButtonsWrapper>
              <Link to="/">
                <SidebarLogoWrapper image={logo} />
              </Link>
              {currentUser && (
                <Avatar
                  image={currentUser.image_url || avatar}
                  name={currentUser.name}
                  route={location.pathname}
                  onClick={this.hideMenu}
                />
              )}
              {links.map(link => (
                <SidebarButton to={link.route} route={location.pathname} onClick={this.hideMenu} key={link.route}>
                  {link.notificationKey && (
                    <NotificationDot count={currentUser[link.notificationKey]}>
                      {currentUser[link.notificationKey]}
                    </NotificationDot>
                  )}
                  <SidebarButtonImage src={link.image} alt="" />
                  <NanoTitle>{link.name}</NanoTitle>
                </SidebarButton>
              ))}
            </SidebarButtonsWrapper>
          </NavigationWrapper>
        </Wrapper>

        <Hamburger isActive={sideMenu} click={this.sideMenu} />
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userRequest: () => dispatch(fetchUser()),
  };
}

function mapStateToProps(state) {
  return {
    currentUser: state.getIn(['membershipReducer', 'currentUser']),
  };
}

Sidebar.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  userRequest: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);
