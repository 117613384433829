import styled from 'styled-components';
import Colors from 'theme/Colors';

export const NotFoundContainer = styled.div`
  width: 100%;
  position: relative;
  left: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: Lato;
    color: ${Colors.navy};
    margin: 0;
    font-weight: 900;
    letter-spacing: 1.25px;
    font-size: 1.5em;
    text-align: center;
    padding-bottom: 38px;
  }
`;
