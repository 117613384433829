/* eslint-disable no-param-reassign */
import { Map } from 'immutable';
import { PROGRAM_REQUEST, PROGRAM_SUCCESS, PROGRAM_FAILURE } from './actions';

const INITIAL_STATE = Map({
  isFetching: true,
  entry: {},
});

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROGRAM_REQUEST:
      return state.set('isFetching', true);
    case PROGRAM_SUCCESS: {
      const { response } = action;
      if (response.program)
        return state.set('isFetching', false).set('entry', response.program);
      return state.set('isFetching', false).set('entry', response);
    }
    case PROGRAM_FAILURE:
      return state.set('isFetching', false);
    default:
      return state;
  }
};
